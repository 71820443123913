import React from "react";
import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import util from "../../dynamic-page/util";
import descriptiveContent from "../../../utils/descriptiveContent";
import InternalNote from "./intertnalNote";
import ShippingContent from "./shippingContent";
import PropTypes from "prop-types";

const OrderRowAction = ({actions, style, callBack}) => {
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500";
        } else {
            return "text-gray-300 hover:text-gray-500";
        }
    };
    return (
        <div className="flex flex-col gap-1">
            <div className={"flex justify-center items-center"}>


                {/* <util.popUp
                    toggle={(selected) =>
                        descriptiveContent(
                            <div>
                                <UI.Button
                                    item={{
                                        buttonType: "flat",
                                        className: `p-[2px]  ${activeStyle(
                                            actions?.customer_note_enabled
                                        )}`,
                                    }}
                                >
                                    <Icons.buyerNote/>
                                </UI.Button>
                            </div>,
                            "Customer note"
                        )
                    }
                    action={"hover"}
                    leftSpace={style?.leftSpace}
                >
                    <div
                        style={{hyphens: "auto"}}
                        className={
                            "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
                        }
                    >
                        {actions?.customer_note ? (
                            util.upperCaseString(actions?.customer_note)
                        ) : (
                            <span className={"text-gray-400"}>Buyer note is empty!</span>
                        )}
                    </div>
                </util.popUp> */}

                {/* Internal note is here */}
                <util.popUp
                    toggle={(selected) =>
                        descriptiveContent(
                            <div className={"relative"}>
                                <UI.Button
                                    item={{
                                        buttonType: "flat",
                                        className: `p-[2px] w-[23px] h-[23px]  ${activeStyle(
                                            actions?.internal_notes
                                        )}`,
                                    }}
                                >
                                    <Icons.internalNote className=""/>
                                </UI.Button>
                                {actions?.internal_notes > 0 && (
                                    <div
                                        className={
                                            "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                                        }
                                    >
                                        {actions?.internal_notes}
                                    </div>
                                )}
                            </div>,
                            "Internal note",
                            null,
                            "top"
                        )
                    }
                    leftSpace={style?.leftSpace}
                >
                    <div className={"w-[525px]"}>
                        <InternalNote
                            callBack={callBack ? callBack : null}
                            notes={actions?.internal_notes}
                            returnId={actions.id}
                            id={
                                actions?.item_id
                                    ? `${actions?.order_id}/${actions?.item_id}`
                                    : actions?.order_id
                            }
                        />
                    </div>
                </util.popUp>
            </div>
            <div className={"flex justify-center items-center"}>
                {/* Shipping Content is here */}

                <util.popUp
                    toggle={(selected) =>
                        descriptiveContent(
                            <div>
                                <UI.Button
                                    item={{
                                        buttonType: "flat",
                                        className: `p-[2px] w-[23px] h-[23px] ${activeStyle(
                                            actions?.shipping_content
                                        )}`,
                                    }}
                                >
                                    <Icons.shippingContent className=""/>
                                </UI.Button>
                            </div>,
                            "Shipping content",
                            null,
                            "top"
                        )
                    }
                    leftSpace={style?.leftSpace}
                    className=""
                >
                    <div className={"px-[8px] py-[4px]"}>
                        <ShippingContent
                            shipping_content={actions?.shipping_content}
                            gtip={actions?.gtip}
                            id={
                                actions?.item_id
                                    ? `${actions?.order_id}/${actions?.item_id}`
                                    : actions?.order_id
                            }
                            identifier={actions?.id}
                            callBack={(data) => {
                                if (callBack) {
                                    callBack(data);
                                }
                            }}
                        />
                    </div>
                </util.popUp>
            </div>
        </div>
    );
};

OrderRowAction.propTypes = {
    actions: PropTypes.object.isRequired,
    callBack: PropTypes.func,
    style: PropTypes.object,
};

export default OrderRowAction;

import upperCase from "../../dynamic-page/util/upperCaseString";
import Icons from "../../orders/icons";
import Note from "../../pickup-points/components/note";

const tableView = (list, tableActions) => {
  const Doc = Icons.docText;
  const Notifi = Icons.notificationDot;
  const renderStateCountry = (item) => {
    if (item.states?.length === 1) {
      return (
        <div className="flex justify-start gap-2 items-center">
          {item?.states[0]?.country?.flag && (
            <img
              alt="flag"
              className="w-[28px] h-[20px] bg-none object-cover"
              src={item?.states[0]?.country?.flag}
            />
          )}
          <p className="font-medium text-[14px] leading-[21px] text-gray-900">
            {item?.states[0]?.name ? upperCase(item?.states[0]?.name, "first") : "-"}
          </p>
        </div>
      );
    }
  
    if (item.states?.length > 1) {
      return (
        <button
          className="flex justify-start items-center text-[14px] leading-[14px] text-blue-700 font-medium"
          onClick={() => {
            tableActions?.openStatesModal(item);
          }}
        >
          Multiple
        </button>
      );
    }
  
    return <span>-</span>;
  };
  return {
    list: list,
    theme: {
      note: (item) => (
        <div className="flex justify-between items-center gap-1 ">
          <Note
            icon={<Doc className="w-[18px] h-[18px] " />}
            item={item}
            callBack={tableActions.onUpdateNote}
          />
        </div>
      ),
      status: (item) => (
        <div className=" flex justify-start  items-center">
          <div
            className={`w-fit flex justify-center items-center gap-2 px-2.5 py-0.5 rounded-full ${
              item?.provider?.type === 'API' ? "bg-green-100" : "bg-blue-100"
            }`}
          >
            <Notifi
              className={`w-3 h-3 ${
                item?.provider?.type === 'API' ? "text-green-500" : "text-blue-500"
              }`}
            />
            <p
              className={`font-medium text-[12px] leading-[18px] ${
                item?.provider?.type === 'API' ? "text-green-800" : "text-blue-800"
              }`}
            >
              {item?.provider?.type === 'API' ? "Integrated" : "Imported"}
            </p>
          </div>
        </div>
      ),
      Provider: (item) => (
        <div className=" flex justify-start gap-1.5 items-center">
          {item?.provider?.logo ? (
            <img
              alt="logo"
              className="w-4 h-4 bg-none"
              src={item?.provider?.logo}
            />
          ) : null}
          <p className="font-medium text-sm text-gray-900">
            {item?.provider?.name
              ? upperCase(item?.provider?.name, "first")
              : "-"}
          </p>
        </div>
      ),
      name: (item) => (
        <div className="min-w-max gap-1 flex items-center justify-start font-[600] text-[14px] leading-[14px]">
          {item?.international_carrier?.logo ? (
            <img
              alt="logo"
              className="w-6 h-5 bg-none"
              src={item?.international_carrier?.logo}
            />
          ) : null}
          {upperCase(item?.international_carrier?.name)}
        </div>
      ),

      code: (item) => (
        <div className="min-w-min flex justify-start font-medium text-sm text-gray-900">
          {item?.code}
        </div>
      ),
      "STATE, COUNTRY": (item) => renderStateCountry(item),
      shippingCost: (item) => (
        <div className="flex justify-end text-blue-700 text-sm font-[500]">
          <button
            onClick={() => {
              const carrier_ids = {
                carrier_ids: {
                  value: item?.id,
                  label: item?.name,
                },
              };
              const serializedObject = encodeURIComponent(
                JSON.stringify(carrier_ids)
              );

              window.open(
                `/logistics/shipping-costs?filter[carrier_ids]=${item?.id}#bounded=${serializedObject}`,
                "_blank"
              );
            }}
            className="cursor-pointer relative z-0"
          >
            Shipping Costs
          </button>
        </div>
      ),
    },
  };
};
export default tableView;

import upperCaseString from "../../../../dynamic-page/util/upperCaseString";
const formView = ( shipmentItem, label) => [
  {
    label: "Allocation Warehouse",
    name: "warehouse",
    type: "Select",
    api: {
      url: "/api/v1/warehouses?include=state",
      //url: '/api/v1/warehouses',
      skipCancel: true,
      query: "&filter[name]={query}",
      view: "/api/v1/warehouses",
      optionValue: (item) => ({
        value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
        label: upperCaseString(item?.name, "word", true),
      }),
      ...(label && { list: [{ label: "Default", value: null }] }),
    },
    className: "col-span-3",
    // defaultValue: shipmentItem?.warehouseId ||  null,
    defaultBound: shipmentItem?.warehouse || null,
    reset: false,
    clear: false,

    placeholder: "Select the ships from warehouse",
  },
  {
    label: "Pick-up Point",
    name: "onWarehouse",
    type: "Select",
    api: {
      url: "/api/v1/pickup-points?include=state",
      query: "&filter[name]={query}",
      view: "/api/v1/warehouses",
      optionValue: (item) => ({
        // value: item?.id,
        type: "warehouse",
        value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
        label: upperCaseString(item?.name, "word", true),
      }),
      ...(label && { list: [{ label: "Default", value: null }] }),
    },
    className: "col-span-3",
    defaultBound: shipmentItem?.onWarehouse || null,
    // reset: true,
    clear: true,
    optional:true,
    placeholder: "Select the pick-up point (if exist)",
  },
];

export default formView;

import upperCaseString from "../../dynamic-page/util/upperCaseString";
import {useSelector} from "react-redux";
import React from "react";

const FiltersView = (selectedFilers) => {
    const filtersMeta = useSelector(
        (state) => state.goodsAcceptance?.filtersMeta
    );
    return [
        {
            name: "title",
            label: "Product Title",
            placeholder: "Filter by product title",
            type: "Input",
            defaultValue: selectedFilers?.title || null,
            toggle: true,
            clear: true,
        },
        {
            name: "apid",
            label: "APID",
            placeholder: "Filter by product APID",
            type: "Input",
            defaultValue: selectedFilers?.apid || null,
            toggle: true,
            clear: true,
        },
        {
            name: "created_at",
            label: "Create Date",
            placeholder: "Filter by create date",
            type: "DateRange",
            defaultValue: selectedFilers?.created_at || null,
            toggle: true,
            clear: true,
        },
        {
            name: "brand",
            label: "Brand",
            placeholder: "Select Brand",
            type: "Select",
            api: {
                url: "/api/v1/brands",
                query: "?filter[name]={query}",
                optionValue: (item) => ({
                    value: item.id,
                    label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                }),
            },
            features: {
                isMulti: true,
            },
            defaultValue: selectedFilers?.brand || null,
            toggle: true,
            clear: true,
        },
        {
            name: "assigned",
            label: "Assigned Employee",
            placeholder: "Filter by employee",
            type: "Select",
            api: {
                url: "/api/v1/users?filter[status]=1",
                view: "/api/v1/user",
                query: "&filter[name]={query}",
                optionValue: (item) => {
                    return { 
                    value:  item?.id,
                    label: item?.name,
                    avatar: item?.image_url || item?.profile_image,
                }},
            },
            features: {
                isMulti: true,
                formatOptionLabel: ({label, avatar}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
                className={
                    "block overflow-hidden flex flex-col justify-center items-center "
                }
            >
              <img
                  src={avatar || "/assets/images/defaultAvatar.png"}
                  className="w-5 h-5 rounded-full object-contain"
              />
            </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            defaultValue: selectedFilers?.assigned,
            toggle: true,
            clear: true,
        },
        {
            name: "inventory_count",
            label: "Inventory Count",
            placeholder: "Filter by inventory count",
            type: "Range",
            defaultValue: selectedFilers?.inventory_count || null,
            toggle: true,
            clear: true,
        },
        {
            name: "weight",
            label: "Weight",
            placeholder: "Filter by weight",
            type: "Range",
            defaultValue: selectedFilers?.weight || null,
            toggle: true,
            clear: true,
        },
        {
            name: "cost",
            label: "Total Inventory Cost",
            placeholder: "Filter by cost",
            type: "Range",
            defaultValue: selectedFilers?.cost || null,
            toggle: true,
            clear: true,
        },
        {
            name: "awaiting_acceptance",
            label: "Awaiting Acceptance",
            placeholder: "Filter by awaiting acceptance",
            type: "RadioButton",
            list: [
                {
                    label: "Yes",
                    value: "true",
                },
                {
                    label: "No",
                    value: "false",
                },
            ],
            defaultValue: selectedFilers?.awaiting_acceptance || null,
            toggle: true,
            clear: true,
        },
    ];
};

export default FiltersView;

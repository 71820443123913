import React from "react";
import Icons from "../../icons";
import util from "../../../dynamic-page/util";
import descriptiveContent from "../../../../utils/descriptiveContent";
import Note from "../../../dynamic-page/util/note";
import { updateRow, addNote, deleteNote } from "../../../../store/orders";
import Button from "../../../dynamic-form/elements/Button";
import ShippingContent from "./shippingContent";
import PropTypes from "prop-types";

const OrderRowAction = ({ actions, style, callBack, portal, view }) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const InternalNo = Icons.internalNote;
  const Shipping = Icons.shippingContent;

  return (
    <div className="flex flex-col gap-1">
      <div className={"flex justify-center items-center"}>
        {/* Internal note is here */}
        <util.popUp
          portal={portal}
          toggle={(selected) =>
            descriptiveContent(
              <div className={"relative"}>
                <Button
                  item={{
                    buttonType: "flat",
                    className: `p-[2px] w-[23px] h-[23px]  ${activeStyle(
                      actions?.internal_notes
                    )}`,
                  }}
                >
                  <InternalNo className="" />
                </Button>
                {actions?.internal_notes > 0 && (
                  <div
                    className={
                      "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                    }
                  >
                    {actions?.internal_notes}
                  </div>
                )}
              </div>,
              "Internal note",
              null,
              "top"
            )
          }
          leftSpace={style?.leftSpace}
        >
          <div className={"w-[525px]"}>
            <Note
              callBack={callBack || null}
              addNote={addNote}
              deleteNote={deleteNote}
              page={"orders"}
              updateRow={updateRow}
              notes={actions?.internal_notes}
              id={
                actions?.item_id
                  ? `${actions?.order_id}/${actions?.item_id}`
                  : actions?.order_id
              }
              view={view}
            />
          </div>
        </util.popUp>
      </div>
      <div className={"flex justify-center items-center"}>
        {/* Shipping Content is here */}

        <util.popUp
          portal={portal}
          toggle={(selected) =>
            descriptiveContent(
              <div>
                <Button
                  item={{
                    buttonType: "flat",
                    className: `p-[2px] w-[23px] h-[23px] ${activeStyle(
                      actions?.shipping_content
                    )}`,
                  }}
                >
                  <Shipping className="" />
                </Button>
              </div>,
              "Shipping content",
              null,
              "top"
            )
          }
          leftSpace={style?.leftSpace}
          className=""
        >
          <div className={"px-[8px] py-[4px]"}>
            <ShippingContent
              shipping_content={actions?.shipping_content}
              gtip={actions?.gtip}
              id={
                actions?.item_id
                  ? `${actions?.order_id}/${actions?.item_id}`
                  : actions?.order_id
              }
              callBack={(data) => {
                if (callBack) {
                  callBack(data);
                }
              }}
              view={view}
            />
          </div>
        </util.popUp>
      </div>
    </div>
  );
};
OrderRowAction.propTypes = {
  actions: PropTypes.shape({
    internal_notes: PropTypes.number,
    shipping_content: PropTypes.bool,
    order_id: PropTypes.string.isRequired,
    item_id: PropTypes.string.isRequired, // Validate item_id
    gtip: PropTypes.string.isRequired, // Validate gtip
    // You can add more action properties here as needed
  }).isRequired,
  style: PropTypes.shape({
    leftSpace: PropTypes.string,
    // other style properties
  }),
  callBack: PropTypes.func,
  portal: PropTypes.object,
  view: PropTypes.bool,
};
export default OrderRowAction;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
const RadioButton = ({
  item,
  value = "",
  validationMessage,
  bind,
  handleChange,
}) => {
  const handleUpdate = (option) => {
    handleChange(option, item?.name);
    //  bind the whole object not just the value
    bind &&
      bind(
        item?.list?.find((obj) => obj.value === option),
        item?.name
      );
  };

  const isChecked = (option) => {
    return value === option;
  };

  useEffect(() => {
    bind &&
      bind(
        item?.list?.find((obj) => obj.value === value),
        item?.name
      );
  }, [value]);

  // Return Input in view
  return (
    <div className={`flex  gap-2  flex-row  ${item?.outerClassName || ""}`}>
      {item?.list && item?.list?.length > 0 ? (
        item?.list?.map((option, index) => (
          <div className={"flex gap-2 items-start"} key={item?.name + index}>
            <input
              name={item?.name}
              id={`${item?.name}-${option?.label}`}
              type={"radio"}
              checked={isChecked(option.value)}
              disabled={item.disabled}
              className={`bg-gray-50 rounded-lg border border-gray-300 ${
                item.disabled && "disabled"
              }  disabled:text-gray-400   ${item.innerClassName || ""} ${
                validationMessage ? "border-red-500 border-2" : ""
              }`}
              onChange={() => {
                handleUpdate(option.value);
              }}
            />
            {/* <span className="flex flex-row gap-1">{option.label}</span> */}
            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
              <label
                htmlFor={`${item?.name}-${option?.label}`}
                className="text-gray-900 text-[14px] font-normal leading-[14px]"
              >
                {option.label} {option?.count && `(${option?.count})`}
              </label>
              <span className="text-gray-500 text-[12px] leading-[18px] font-normal ">
                {option?.description}
              </span>
            </div>
          </div>
        ))
      ) : (
        <div className={"text-gray-400"}> No Item!</div>
      )}
    </div>
  );
};
RadioButton.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        count: PropTypes.number,
      })
    ).isRequired,
    disabled: PropTypes.bool,
    outerClassName: PropTypes.string,
    description: PropTypes.string,
    innerClassName: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  validationMessage: PropTypes.string,
  bind: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

export default RadioButton;

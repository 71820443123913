import React, { useEffect, useState } from "react";
import axios from "axios";

import MainModal from "../../dynamic-page/MainModal";
import Util from "../../dynamic-page/util";
import UI from "../../dynamic-form/elements";
import Icons from "../../orders/icons";
import { HiExternalLink } from "react-icons/hi";
import SelectItems from "../../supply-channel-listings/modals-view/searchAndConnect";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import CopyText from "../util/copyText";
import upperCase from "../util/upperCaseString";
import {v4 as uuidv4} from "uuid";
const productTooltip = (
  <>
    <span className="flex ">
      <Icons.info
        className="w-5 h-5  text-gray-300"
        data-tooltip-id="productTooltip"
      />
    </span>
    <Tooltip
      id="productTooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[250px]">
        <p className="text-[14px] font-medium text-gray-900">Select Product</p>
        <p className="text-xs text-gray-600 whitespace-pre-wrap">
          Select a product to connect listing(s) and access detailed supply
          costs and inventory levels.
        </p>
      </div>
    </Tooltip>
  </>
);

// Main Component: ConnectChannelModal
const ConnectProductModal = React.memo(
  ({
    callBack,
    toggle,
    urlParams,
    defaultListing,
    noAddMore,
    mapListings,
    defaultListing2,
    newDesign,
    salesChannelItem,
  }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
      products: [],
    });

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
      setData({
        products: [],
      });
    }, [open]);

    const mapProducts = (item) => ({
      id: item.id,
      label: item.title,
      image: item.image,
      apid: item.apid,
      type: item.type,
      brand: item?.brand?.name,
      assign: item?.brand?.assigned,
      numbers: {
        "Supply CL": item.supply_channels_count,
        "Sales CL": item.sales_channels_count,
        Inventory: item.inventory_count,
        Orders: 0,
        "Avg. Cost": item?.average_cost_usd
          ? `$${item?.average_cost_usd}`
          : "-",
      },
    });

    const defaultItem = defaultListing ? mapListings?.(defaultListing) : null;
    const defaultItem2 = defaultListing2
      ? mapListings?.(defaultListing2)
      : null;

    const onConnect = () => {
      setSubmitting(true);
      callBack(
        data.products[0],
        () => setSubmitting(false),
        () => setOpen(false)
      ).then((rs) => {
        setSubmitting(false);
        if (rs) {
          setOpen(false);
        }
      });
    };

    return (
      <>
        <button onClick={() => setOpen(true)} className="text-blue-500">
          {toggle()}
        </button>
        {open && (
          <MainModal
            className="max-w-[1000px] "
            containerClassName="!px-5"
            headerClassName="!pl-6"
            item={{
              title: "Connect Product",
              cancel: () => setOpen(false),
              view: () => (
                <div className="app flex flex-col   gap-3 border-t pt-[17px] border-gray-300">
                  {defaultListing2 && (
                    <SelectItems
                      title="Order Item"
                      url="account-listings"
                      mapItems={mapListings}
                      createNewRedirect={false}
                      ItemCard={salesChannelItem}
                      noAddMore={noAddMore}
                      multiple
                      key="sales"
                      defaultItem={defaultItem2}
                      data={data}
                      setData={setData}
                      params="filter[has_product]=false"
                    />
                  )}
                  <SelectItems
                    title="Product"
                    tooltip={productTooltip}
                    newDesign={newDesign}
                    noAddMore={noAddMore}
                    url="product"
                    mapItems={mapProducts}
                    createNewRedirect={true}
                    ItemCard={ProductItem}
                    key="Products"
                    data={data}
                    setData={setData}
                    urlParams={urlParams}
                  />
                  {defaultListing && (
                    <SelectItems
                      title="Listing"
                      url="account-listings"
                      mapItems={mapListings}
                      createNewRedirect={false}
                      ItemCard={salesChannelItem}
                      multiple
                      key="sales"
                      defaultItem={defaultItem}
                      data={data}
                      setData={setData}
                      tooltip={salesTooltip}
                      params="filter[has_product]=false"
                    />
                  )}
                  {data?.products?.length > 0 && (
                    <div className="flex flex-row justify-end gap-[8px] ">
                      <UI.Button
                        item={{
                          label: <div className={"flex flex-row"}>Cancel</div>,
                          buttonType: "alt",
                          className: "h-[37px] !w-[120px] !px-[16px]",
                        }}
                        onClick={() => setOpen(false)}
                      />
                      <UI.Button
                        item={{
                          label: <div className={"flex flex-row"}>Connect</div>,
                          buttonType: "primary",
                          className: "h-[37px] !w-[120px]  !px-[16px]",
                          disabled: submitting,
                        }}
                        onClick={() => onConnect()}
                      />
                    </div>
                  )}
                </div>
              ),
            }}
          />
        )}
      </>
    );
  }
);

export default ConnectProductModal;

const fetchProductMetrics = async (productId, metricType) => {
  try {
    let endpoint = "";
    let dataExtractor = null;

    switch (metricType) {
      case "orders":
        endpoint = `/api/v1/orders?filter[product]=${productId}`;
        dataExtractor = (responseData) =>
          responseData.meta.total || 0; 
        break;
      case "supply_channels":
        endpoint = `/api/v1/supply-channel-listing?filter[product_id]=${productId}`;
        dataExtractor = (responseData) => responseData.meta.total || 0;
        break;
      case "sales_channels":
        endpoint = `/api/v1/account-listings?filter[product]=${productId}`;
        dataExtractor = (responseData) => responseData.meta.total || 0;
        break;
      default:
        return 0;
    }
    const response = await axios.get(endpoint, { withCredentials: true });
    return response.data ? dataExtractor(response.data) : 0;
  } catch (error) {
    console.error(
      `Error fetching ${metricType} for product ${productId}:`,
      error
    );
    return 0;
  }
};

const ProductItem = ({
  item,
  index,
  results,
  searching,
  setSelectedProduct,
  setSearching,
  selectedProduct,
  setData,
  data,
}) => {
  const [metrics, setMetrics] = useState({
    orders: 0,
    supply_channels: item.numbers["Supply CL"] || 0,
    sales_channels: item.numbers["Sales CL"] || 0,
  });
  const [loading, setLoading] = useState({
    orders: false,
    supply_channels: false,
    sales_channels: false,
  });

  useEffect(() => {
    if (item && !searching) {
      // Create an array of metrics to fetch
      const metricsToFetch = ["orders", "supply_channels", "sales_channels"];

      // Set loading state for all metrics
      setLoading((prev) => ({
        orders: true,
        supply_channels: true,
        sales_channels: true,
      }));

      // Fetch all metrics in parallel
      Promise.all(
        metricsToFetch.map((metric) =>
          fetchProductMetrics(item.id, metric).then((value) => ({
            metric,
            value,
          }))
        )
      ).then((results) => {
        // Update all metrics at once
        const newMetrics = { ...metrics };
        const newLoading = { ...loading };

        results.forEach(({ metric, value }) => {
          newMetrics[metric] = value;
          newLoading[metric] = false;
        });

        setMetrics(newMetrics);
        setLoading(newLoading);
      });
    }
  }, [item, searching]);

  const getMetricDisplay = (key) => {
    if (key === "Orders") {
      return loading.orders ? (
        <Skeleton width={50} height={20} />
      ) : (
        metrics.orders
      );
    } else if (key === "Supply CL") {
      return loading.supply_channels ? (
        <Skeleton width={50} height={20} />
      ) : (
        metrics.supply_channels
      );
    } else if (key === "Sales CL") {
      return loading.sales_channels ? (
        <Skeleton width={50} height={20} />
      ) : (
        metrics.sales_channels
      );
    }
    return item?.numbers[key];
  };

  return (
    <div
      key={index}
      className={`result-item  w-[630px]  ${
        searching ? "h-[144px]" : "h-[176px]"
      } flex items-center p-3 mb-1 border shadow-sm rounded-lg`}
    >
      <img
        src={item.image || "/assets/images/noImage.png"}
        alt={item.label}
        className={`result-image 
           w-[120px] h-[120px]
       object-contain rounded`}
      />
      <div className="result-details flex-1 flex flex-col h-full pl-4 justify-between">
        {/* apid and badge here */}
        <div className="flex justify-between items-center">
          <div className={"flex flex-row gap-[4px]"}>
            <button
              type="button"
              className=" text-xs font-normal leading-[18px] hover:text-blue-800 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            >
              {item?.apid}
            </button>
            <CopyText
              className={"text-[12px]"}
              text={item?.apid}
              hint={false}
            />
            <HiExternalLink
              className="w-4 h-4  text-blue-700 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            />
          </div>
          <div className="flex gap-2">
            {item?.type === 1 ? (
              <div className=" h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-teal-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center whitespace-nowrap text-teal-900 text-xs font-medium leading-[18px]">
                    Single Product
                  </div>
                </div>
              </div>
            ) : (
              <div className=" h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-sky-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center whitespace-nowrap text-indigo-800 text-xs font-medium leading-[18px]">
                    Grouped Product
                  </div>
                </div>
              </div>
            )}
            {!searching && results?.results?.length ? (
              <Icons.Trash
                className="w-5 h-5 text-gray-400 cursor-pointer hover:text-red-400"
                onClick={() => {
                  setSelectedProduct([]);
                  setData({ ...data, products: [] });
                  setSearching(true);
                }}
              />
            ) : null}
          </div>
        </div>
        {/* title here */}
        <div>
          <p className="text-[16px] leading-[24px] font-semibold w-[430px] break-words whitespace-pre-wrap ">
            {item?.label?.length > 150
              ? Util.shortenString(item?.label, 150, false, true, null, "top")
              : item?.label}
          </p>
        </div>
        {/* assign */}
        {!searching && results?.results?.length && (
          <div className={"flex justify-between"}>
            <div className={"flex flex-row gap-[4px] text-gray-600"}>
              {item?.brand ? (
                <CopyText
                  className={"text-[14px] font-medium text-gray-900"}
                  text={item?.brand}
                  hint={false}
                >
                  {Util.shortenString(upperCase(item?.brand), 30)}{" "}
                </CopyText>
              ) : (
                ""
              )}
            </div>

            <div className="flex items-center gap-[2px] !text-[12px]">
              <button
                type="button"
                className={"text-gray-900 flex flex-row gap-[4px] "}
                onClick={() => {
                  window.open(`/product/${item?.id}`, "_blank");
                }}
              >
                <img
                  src={
                    item?.assign?.profile_image ||
                    "/assets/images/defaultAvatar.png"
                  }
                  className={"w-[20px] h-[20px] rounded-full object-contain"}
                  alt="avatar"
                />
                {Util.upperCaseString(item?.assign?.name, "word")}
              </button>
            </div>
          </div>
        )}
        {/* numbers here */}
        {searching && results?.results?.length ? (
          <div className="flex justify-end">
            <button
              className="text-blue-500 "
              onClick={() => {
                setSelectedProduct([item]);
                setData({ ...data, products: [item] });
                setSearching(false);
              }}
            >
              Select product
            </button>
          </div>
        ) : (
          <div className="flex justify-between px-4">
            {Object.keys(item?.numbers).map((key, index) => (
              <div key={uuidv4()} className="flex flex-col gap-0.5 ">
                <p className="text-xs font-bold text-gray-600">{key}</p>
                <p className="text-xl font-bold  text-purple-700">
                  {getMetricDisplay(key)}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


ProductItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string,
    image: PropTypes.string,
    apid: PropTypes.string,
    type: PropTypes.number,
    brand: PropTypes.shape({
      name: PropTypes.string,
      assigned: PropTypes.object,
    }),
    numbers: PropTypes.object,
  }).isRequired,
  index: PropTypes.number,
  results: PropTypes.object,
  searching: PropTypes.bool,
  setSelectedProduct: PropTypes.func,
  setSearching: PropTypes.func,
  selectedProduct: PropTypes.array,
  setData: PropTypes.func,
  data: PropTypes.object,
};

const salesTooltip = (
  <>
    <span className="flex ">
      <Icons.info
        className="w-5 h-5  text-gray-300"
        data-tooltip-id="my-tooltip"
      />
    </span>
    <Tooltip
      id="my-tooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[250px]">
        <p className="text-[14px] font-medium text-gray-900">
          Select Listing(s)
        </p>
        <p className="text-xs text-gray-600 whitespace-pre-wrap">
          Please select one or more listings to connect to the product.
        </p>
      </div>
    </Tooltip>
  </>
);

import React from "react";
import { HiCheck, HiExternalLink, HiFilter, HiX } from "react-icons/hi";
import priceHandler from "../../../utils/priceHandler";
import Icons from "../../orders/icons";
import Icon from "../icons";
import SupplyCost from "../components/supplyCost";
import components from "../../orders/components";
import InternalNote from "../components/internalNote";
import descriptiveContent from "../../../utils/descriptiveContent";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import CopyText from "../../dynamic-page/util/copyText";
import PopupUp from "../../dynamic-page/util/popUp";
import Button from "../../dynamic-form/elements/Button";
import DateDisplay from "../../dynamic-page/util/dateDisplay";
import TruncateText from "../../dynamic-page/util/truncateText";
import upperCase from "../../dynamic-page/util/upperCaseString";
import quantityHandler from "../../../utils/quantityHandler";
import { FaQuestion } from "react-icons/fa6";

const TableView = (
  list,
  handleSavePrice,
  handleSaveQty,
  handleConnectProduct,
  connect,
  product_id,
  onFilterApply
) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };
  console.log(product_id);
  const Note = Icons.internalNote;
  const Website = Icon.website;
  const Whole = Icon.wholesaler;

  const rightSection = (item) => (
    !product_id ? (
                  <div>
                    {item?.product ? (
                      <div className="flex items-center gap-[2px] !text-[12px]">
                        <span
                          className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${
                            item?.product?.type === 1
                              ? "text-teal-700 bg-teal-100"
                              : "text-indigo-700 bg-indigo-100"
                          } p-[0px]`}
                        >
                          {item?.product?.type === 1 ? "S" : "G"}
                        </span>
                        <button
                          className={
                            "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "
                          }
                          onClick={() => {
                            window.open(`/product/${item?.product?.id}`, "_blank");
                          }}
                        >
                          {item?.product?.brand?.assigned ? (
                            <>
                              {" "}
                              <img
                                alt="brand-assigned"
                                src={
                                  item?.product?.brand?.assigned &&
                                  (item?.product?.brand?.assigned?.profile_image ||
                                    "/assets/images/defaultAvatar.png")
                                }
                                className={
                                  "w-[20px] h-[20px] rounded-full object-cover"
                                }
                              />
                              {upperCase(
                                item?.product?.brand?.assigned?.name,
                                "word"
                              )}
                            </>
                          ) : (
                            <div className="text-gray-400">Unassigned</div>
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center gap-[2px] !text-[12px]">
                        <button
                          className={"flex flex-row gap-[2px]"}
                          onClick={() => {
                            connect.onClick(item);
                          }}
                        >
                          <span className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                            <Icons.Link className={"w-[11px] h-[11px]"} />
                          </span>
                          <div
                            className={
                              "text-red-700 hover:text-red-800 cursor-pointer "
                            }
                          >
                            Not Connected
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex gap-1 items-center">
                    <button
                      className={
                        "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "
                      }
                      onClick={() => {
                        window.open(item?.url, "_blank");
                      }}
                    >
                      {item?.identifier}
                    </button>
                    <CopyText
                      className={"text-[14px]"}
                      text={item?.identifier}
                      hint={false}
                    />
                  </div>
                )
  )

  return {
    list: list ?? [],
    theme: {
      notes: (item) =>
        !item?.ai && (
          <div className={"flex items-center w-[25px]"}>
              <PopupUp
                toggle={(selected) => (
                  <div className={"relative"}>
                    <Button
                      item={{
                        buttonType: "flat",
                        className: `p-[2px]  ${activeStyle(
                          item?.notes?.length > 0
                        )} text-gray-500 hover:text-gray-300`,
                      }}
                    >
                      <Note className="w-4 h-5" />
                    </Button>
                    {item?.notes?.length > 0 && (
                      <div
                        className={
                          "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                        }
                      >
                        {item?.notes?.length}
                      </div>
                    )}
                  </div>
                )}
              >
                <div className={"w-[525px]"}>
                  <InternalNote
                    callBack={null}
                    notes={item?.notes}
                    id={item?.id}
                  />
                </div>
              </PopupUp>
          </div>
        ),
      aiActions: (item) => {
        if (!item?.ai) return undefined;
        return (
          <div className="flex items-center space-x-4 ">
            {/* Approve/Reject Buttons */}
            <div className="flex rounded-lg overflow-hidden shadow">
              <button
                className="bg-green-800 text-white p-2.5 flex items-center justify-center"
                onClick={() => handleConnectProduct(product_id, item)}
              >
                <HiCheck className="w-[18px]  h-[18px] text-white" />
              </button>
              <button
                className="bg-red-600 text-white p-2.5 flex items-center justify-center"
                onClick={() => connect.onClick(item)}
              >
                <HiX className="w-[18px]  h-[18px] text-white" />
              </button>
            </div>

            {/* Percentage Indicator */}
            <div
              className={`${
                item?.suggestion_confidence >= 90
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800"
              } text-brown-700 p-2 rounded-lg shadow text-sm font-medium`}
            >
              {item?.suggestion_confidence.toFixed()}%
            </div>
          </div>
        );
      },

      "last update": (item) => {
        return <DateDisplay item={item} date={item?.updated_at} />;
      },

      channel: (item) => {
        return (
          <div className="flex flex-col w-full gap-2 ">
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 justify-start items-center">
                {item?.channel?.supply_channel_type === "WEBSITE" ? (
                  <Website className={"w-[16px] h-[18px] text-gray-300"} />
                ) : (
                  <Whole className={"w-[16px] h-[18px] text-gray-300"} />
                )}
                <div className="text-gray-900 text-[14px] font-normal leading-[14px]">
                  {item?.["channel"]?.name}
                </div>
              </div>
            </div>

            <div className="">
              <div className="flex items-center">
                {item?.identifier ? (
                  <span className={"text-[12px] text-blue-700"}>
                    {descriptiveContent(
                      item?.identifier ? item?.identifier : "-",
                      "Identifier"
                    )}
                  </span>
                ) : (
                  "-"
                )}
                <CopyText
                  className={"text-[9px]"}
                  text={item?.identifier}
                  hint={false}
                />
                {item?.url && (
                  <HiExternalLink
                    className="w-4 h-4 text-gray-300 cursor-pointer"
                    onClick={() => {
                      window.open(item?.url, "_blank");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      },

      image: (item) => (
        <div
          className={
            "w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"
          }
        >
          <ImageMagnifier
            src={item?.product?.image || item?.image}
            className={"!max-h-[64px]"}
          />
        </div>
      ),
      name: (item) => (
        <div className=" gap-[8px] flex flex-col justify-start w-[100%] ">
          <div
            className={`flex flex-row gap-[8px] whitespace-pre-wrap cursor-pointer`}
          >
            {item?.title ? (
              <TruncateText
                maxLines={2}
                className={
                  "text-[12px] leading-[16px] !text-start text-gray-900   "
                }
                tooltip={true}
              >
                {item?.title || item?.product?.title}
              </TruncateText>
            ) : (
              "-"
            )}
          </div>
          <div className={"flex justify-between w-full items-center"}>
            <div className={"flex flex-row gap-[4px] text-xs font-medium !leading-[18px] text-gray-500"}>
              <span className="">
                {!product_id
                  ? item?.barcode
                  : item?.["channel"]?.name}
              </span>
              {!product_id && (
                <CopyText
                  className={"text-[14px]"}
                  text={item?.barcode}
                  hint={false}
                />
              )}
              {(!product_id && item?.barcode) && descriptiveContent(
                                  <HiFilter
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onFilterApply(
                                        {
                                          barcode: item?.barcode,
                                        },
                                      );
                                    }}
                                    className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
                                  />,
                                  "Filter by Barcode"
                                )}
              {product_id && (
                <>
                  {" "}
                  <HiExternalLink
                    className="w-4 h-4 text-gray-300 hover:text-gray-500 cursor-pointer"
                    onClick={() => {
                      window.open(
                        item?.url,
                        "_blank"
                      );
                    }}
                  />
                  {descriptiveContent(
                    <HiFilter
                      onClick={(e) => {
                        e.stopPropagation();
                        onFilterApply(
                          {
                            channel: [item?.channel?.id],
                          },
                          {
                            channel: {
                              value: item?.channel?.id,
                              label: item?.channel?.name,
                            },
                          }
                        );
                      }}
                      className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
                    />,
                    "Filter by Channel"
                  )}
                </>
              )}
            </div>

            {rightSection(item)}
          </div>
        </div>
      ),

      price: (item) => (
        <div className="w-[100%] justify-end items-center">
          <SupplyCost
            price={{
              usd_price: priceHandler(item?.usd_price),
              price: priceHandler(item?.price),
              data: item,
            }}
            handleSavePrice={handleSavePrice}
          />
        </div>
      ),
      qty: (item) => {
        const getStockDisplay = (item) => {
          const outOfStockElement = (
            <span className="font-medium">-</span>
          );

          const trackableInStock = (
            <components.saveWeight
              text={quantityHandler(item?.quantity)}
              isQuantity={true}
              onSave={(t) => handleSaveQty(t, item)}
              className={
                "min-w-max whitespace-no-wrap group-active:text-green-800 hover:text-green-600 flex text-[24px] text-green-700 font-[700]"
              }
              outerClassName={"!justify-end"}
            />
          );

          const infiniteStock = (
            <Icon.Amount className={"w-5 h-5 text-gray-300 "} />
          );

          if (item?.is_trackable) {
            return !item?.quantity ? outOfStockElement : trackableInStock;
          }
          
            if (item?.quantity === null) {
              return <span className="text-gray-500">
                <FaQuestion className="w-6 h-6" />
            </span>;
            }
            return item?.quantity === 0 ? outOfStockElement : infiniteStock;
        };

        return (
          <div className="w-[100%] flex justify-end items-center">
            {getStockDisplay(item)}
          </div>
        );
      },
      filler: () => <div className="w-4" />,
    }
  };
};
export default TableView;

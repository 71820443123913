import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import {
  HiColorSwatch,
  HiStar,
  HiFilter,
  HiExternalLink,
} from "react-icons/hi";
import PropTypes from "prop-types"; // Import PropTypes
import descriptiveContent from "../../../utils/descriptiveContent";
import { useSelector } from "react-redux";
import CopyText from "../../dynamic-page/util/copyText";
import shortenString from "../../dynamic-page/util/shortenString";
import PopupUp from "../../dynamic-page/util/popUp";

const TableView = ({ salesListing, productList, hide, handleTableFilters, pageData, connect, item }) => {
  return (
    <div className={"flex justify-between gap-1"}>
      <div className={"flex flex-row gap-[4px] whitespace-nowrap text-gray-500"}>
        {util.shortenString(salesListing?.brand, 30)}
        {!hide?.brandFilter && (
          <CopyText
            className={"text-[14px]"}
            text={salesListing?.brand}
            hint={false}
          />
        )}
        {hide?.brandFilter &&
          descriptiveContent(
            <HiFilter
              onClick={() => {
                handleTableFilters(
                  {
                    ...pageData?.filters?.filters,
                    brand: [salesListing?.brand],
                  },
                  {
                    ...pageData?.boundFilters,
                    brand: {
                      value: salesListing?.brand,
                      label: salesListing?.brand,
                    },
                  }
                );
              }}
              className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
            />,
            "Filter by Brand"
          )}
      </div>
      {productList ? (
        <div className="flex items-center gap-[6px] !text-[12px]">
          <span
            className={`w-[18px] h-[18px] rounded-full  flex justify-center items-center text-xs font-medium ${
              productList?.type === 1
                ? "text-teal-700 bg-teal-100"
                : "text-indigo-700 bg-indigo-100"
            } p-[0px]`}
          >
            {productList?.type === 1 ? "S" : "G"}
          </span>
          <div
            className={
              "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row items-center gap-[6px] "
            }
          >
            {productList?.brand?.assigned ? (
              <>
                <button
                  onClick={() => {
                    window.open(`/product/${productList?.id}`, "_blank");
                  }}
                  className={
                    "text-blue-700 w-max hover:text-blue-800 cursor-pointer text-[14px] leading-[21px] flex flex-row items-center gap-[6px] whitespace-nowrap"
                  }
                >
                  <img
                    alt="avatar"
                    src={
                      productList?.brand?.assigned?.image_url ||
                      "/assets/images/defaultAvatar.png"
                    }
                    className={"w-[18px] h-[18px] rounded-full object-cover"}
                  />
                  {util.upperCaseString(
                    productList?.brand?.assigned?.name,
                    "word"
                  )}
                </button>
                <CopyText
                  className={"text-[12px]"}
                  text={productList?.apid}
                  hint={false}
                  iconClass={"!w-[14px] !h-[14px]"}
                />
                {hide?.brandFilter &&
                  descriptiveContent(
                    <HiFilter
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTableFilters(
                          {
                            ...pageData?.filters?.filters,
                            assigned_user: [
                              salesListing?.product?.brand?.assigned.id,
                            ],
                          },
                          {
                            ...pageData?.boundFilters,
                            assigned_user: {
                              value: salesListing?.product?.brand?.assigned?.id,
                              label:
                                salesListing?.product?.brand?.assigned?.name,
                            },
                          }
                        );
                      }}
                      className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
                    />,
                    "Filter by Assign User"
                  )}
              </>
            ) : (
              <button
                className="text-gray-400"
                onClick={() => {
                  window.open(`/product/${productList?.id}`, "_blank");
                }}
              >
                Unassigned
              </button>
            )}
          </div>
        </div>
      ) : (
        <button
          className={"flex flex-row gap-[2px]"}
          onClick={() => {
            connect.onClick(item);
          }}
        >
          <span className="w-[18px] h-[18px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
            <Icons.Link className={"w-[11px] h-[11px]"} />
          </span>
          <div className={"text-red-700 hover:text-red-800 cursor-pointer "}>
            Not Connected
          </div>
        </button>
      )}
    </div>
  );
};

TableView.propTypes = {
  salesListing: PropTypes.object,
  productList: PropTypes.object,
  hide: PropTypes.object,
  handleTableFilters: PropTypes.func,
  pageData: PropTypes.object,
  connect: PropTypes.object,
  item: PropTypes.object,
};

const PDPView = ({ channel, salesListing, handleTableFilters, pageData, openListingModal }) => {
  return (
    <div className="flex justify-between w-full gap-1">
      <div className="flex gap-1 justify-start items-center">
        <PopupUp
          toggle={(selected) =>
            descriptiveContent(
              <img
                alt="flag"
                src={channel?.country?.flag}
                className={"h-[14px] max-w-[16.6px] rounded-[2px]"}
                style={{ objectFit: "cover" }}
              />,
              channel?.country?.name
            )
          }
          action={"hover"}
        >
          <div className={"px-[8px] py-[4px]"}> {channel?.country?.name} </div>
        </PopupUp>
        <img
          className="h-[12px] max-w-[12px] object-contain"
          src={channel?.marketplace?.logo}
          alt="marketplace"
        />
        <div className="text-gray-900 font-medium text-[14px] leading-[21px]">
          {shortenString(channel?.name, 30)}
        </div>
        {descriptiveContent(
          <HiFilter
            onClick={() => {
              handleTableFilters(
                {
                  ...pageData?.filters?.filters,
                  channel: [channel?.id],
                },
                {
                  ...pageData?.boundFilters,
                  channel: {
                    value: channel?.id,
                    label: channel?.name,
                  },
                }
              );
            }}
            className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
          />,
          "Filter by Channel"
        )}
      </div>
      <div className="">
        <div className="flex items-center gap-1">
          {descriptiveContent(
            <button
              className={
                "text-[14px] leading-[21px] cursor-pointer text-blue-700 font-medium "
              }
              onClick={() => openListingModal()}
            >
              {salesListing?.identifier ? salesListing?.identifier : "-"}
            </button>,
            "Identifier Value"
          )}
          <CopyText
            className={"text-[14px]"}
            text={salesListing?.identifier}
            hint={false}
          />
          <HiExternalLink
            className="w-[14px] h-[14px] text-gray-300 hover:text-gray-500 cursor-pointer"
            onClick={() => {
              window.open(
                `${channel?.url}/gp/product/${salesListing?.identifier}`,
                "_blank"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

PDPView.propTypes = {
  channel: PropTypes.object,
  salesListing: PropTypes.object,
  handleTableFilters: PropTypes.func,
  pageData: PropTypes.object,
  openListingModal: PropTypes.func,
};

const Item = ({
  item,
  product,
  connect,
  hideVariant,
  hide,
  handleTableFilters,
  product_id,
  openListingModal,
}) => {
  const salesListing = item?.sales_channel_listing

  const channel = item?.account?.channel;

  const productList = product?.[0];
  const pageData = useSelector((state) => state.salesListing);

  return (
    <div className={"w-full gap-[0px] flex flex-col !px-[8px] !py-[12px]"}>
      {!hideVariant && (
        <div className="flex justify-between text-[14px] font-bold text-orange-700">
          <div className="flex items-center justify-between gap-2">
            <p>
              {salesListing?.["reviews_count"]
                ? salesListing?.["reviews_count"] + " Ratings"
                : "No Rating"}
            </p>
            <div className="flex items-center text-orange-600">
              {salesListing?.["ratings"] && <HiStar />}
              &nbsp;
              {salesListing?.["ratings"]
                ? (+salesListing?.["ratings"])?.toFixed(1)
                : "-"}
            </div>
          </div>
          <div className="flex items-center text-orange-600">
            <HiColorSwatch />
            &nbsp;
            {salesListing?.["variation_count"]
              ? salesListing?.["variation_count"] + " Variants"
              : "No variant"}
          </div>
        </div>
      )}
      <div className="w-full flex flex-row h-[63px] whitespace-pre-wrap">
        {salesListing?.name || salesListing?.product?.title ? (
          <util.truncateText
            maxLines={3}
            popUpOuterClassName="w-full"
            popUpToggleClassName="w-full"
            className={"text-[14px] text-[#111928]"}
            tooltip={true}
            innerClassName="text-left !leading-[18px]"
            ellipsisClass="!top-0 !right-0 h-fit"
          >
            {productList?.brand?.assigned
              ? salesListing?.product?.title
              : salesListing?.name}
          </util.truncateText>
        ) : (
          "-"
        )}
      </div>

      {!product_id ? (
        <TableView
          salesListing={salesListing}
          productList={productList}
          hide={hide}
          handleTableFilters={handleTableFilters}
          pageData={pageData}
          connect={connect}
          item={item}
        />
      ) : (
        <PDPView
          channel={channel}
          salesListing={salesListing}
          handleTableFilters={handleTableFilters}
          pageData={pageData}
          openListingModal={openListingModal}
        />
      )}
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    sales_channel_listing: PropTypes.object,
    sales_channel_listings: PropTypes.array,
    account: PropTypes.object,
  }).isRequired,
  product: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.shape({
        assigned: PropTypes.object,
      }),
      apid: PropTypes.string,
      type: PropTypes.number,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  connect: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  hideVariant: PropTypes.bool,
  hide: PropTypes.shape({
    brandFilter: PropTypes.bool,
  }),
  handleTableFilters: PropTypes.func.isRequired,
  product_id: PropTypes.bool,
  openListingModal: PropTypes.func,
};

export default Item;
import constant from "../../../constant";
import Icons from "../../orders/icons";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { TransferCostTooltip } from "..";
import * as Yup from "yup";

const formView = (formDefaultValue) => {
  return [
    {
      name: "name",
      label: "Name",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. Pegasus, Amazon SAZ1...",
      defaultValue: formDefaultValue?.name || null,
      className: "col-span-4",
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("Name is required"),
    },
    {
      name: "transfer_cost",
      label: "Transfer Cost",
      type: "number",
      placeholder: "ex. $7 per kg",
      defaultValue: formDefaultValue?.transfer_cost || null,
      className: "col-span-2",
      innerClassName: "border border-gray-300 bg-gray-50",
      labelDiscription: "US$ per kg",
      reset: false,
      clear: false,
      labelIcon: TransferCostTooltip("Modal"),
    },
    {
      name: "street1",
      label: "Street 1",
      type: "input",
      placeholder: "ex. 8 The Green",
      defaultValue: formDefaultValue?.street1 || null,
      className: "col-span-3",
      icon: <Icons.mapPin className="text-gray-500" />,
      innerClassName: "border border-gray-300 bg-gray-50",
        validation: Yup.string().required("Street 1 is required"),
    },
    {
      name: "street2",
      label: "Street 2",
      type: "input",
      placeholder: "ex. Ste A",
      defaultValue: formDefaultValue?.street2 || null,
      className: "col-span-3 ",
      optional: true,
      icon: <Icons.mapPin className="text-gray-500" />,
      innerClassName: "border border-gray-300 bg-gray-50",
    },
    {
      name: "country_id",
      label: "Country",
      placeholder: "Select the country",
      type: "Select",
      api: {
        url: constant.APIURL.GET_COUNTRIES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
          flag: item.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={flag?.toLowerCase()} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: formDefaultValue?.country?.id || null,
      validation: Yup.string().required("Country is required"),
      className: "col-span-2 bg-gray-50",
      innerClassName2: " bg-gray-50",
    },
    {
      name: "state_id",
      label: "State",
      placeholder: "Select the state",
      type: "Select",
      features: {
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: "/api/v1/state?filter[country_id]={country_id}",
        query: "&filter[name]={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: formDefaultValue?.state?.id || null,
      validation: Yup.string().required("State is required"),
      className: "col-span-2",
      innerClassName: " bg-gray-50",
    },
    {
      name: "city",
      label: "City",
      type: "input",
      placeholder: "ex. Dover",
      defaultValue: formDefaultValue?.city || null,
      className: "col-span-2",
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("City is required"),
    },
    {
      name: "postal_code",
      label: "Zip / Postal Code",
      type: "input",
      placeholder: "ex. 19901",
      defaultValue: formDefaultValue?.postal_code || null,
      className: "col-span-2",
      innerClassName: "border border-gray-300 bg-gray-50",
      //   validation: Yup.string().required("Zip / Postal Code is required"),
    },
    {
      name: "phone",
      label: "Phone",
      type: "input",
      placeholder: "ex. + (1) 234 567 89 00",
      defaultValue: formDefaultValue?.phone || null,
      className: "col-span-2",
      //   validation: Yup.string().required('Phone is required'),
      optional: true,
      innerClassName: "border border-gray-300 bg-gray-50",
    },
    {
      name: "email",
      label: "Email",
      type: "input",
      placeholder: "ex. info@arbitbox.com",
      defaultValue: formDefaultValue?.email || null,
      className: "col-span-2  ",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
      //   validation: Yup.string().email('Invalid email').required('Email is required'),
    },

    {
      name: "note",
      label: "Note",
      type: "Input",
      placeholder: "ex. Reservation required or only hazmat accepting...",
      defaultValue: formDefaultValue?.note || null,
      className: "col-span-6",
      optional: true,
      innerClassName: "border border-gray-300 bg-gray-50",
    },
  ];
};
export default formView;

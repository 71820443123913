import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FiltersView = (selectedFilers) => {
  return [
    {
      name: "title",
      label: "Product Title",
      placeholder: "Filter by product title",
      type: "Input",
      defaultValue: selectedFilers?.title || null,
      toggle: true,
      clear: true,
    },
    {
      name: "apid",
      label: "APID",
      placeholder: "Filter by product APID",
      type: "Input",
      defaultValue: selectedFilers?.apid || null,
      toggle: true,
      clear: true,
    },
    {
      name: "assigned",
      label: "Assigned Employee",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users?filter[status]=1",
        view: "/api/v1/user",
        query: "&filter[name]={query}",
        optionValue: (item) => {
            return { 
            value: item?.id,
            label: item?.name,
            avatar: item?.image_url || item?.profile_image,
        }},
    },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.assigned,
      toggle: true,
      clear: true,
    },
    {
      name: "brand",
      label: "Brand",
      placeholder: "Select Brand",
      type: "Select",
      api: {
        url: "/api/v1/brands",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        }),
      },
      defaultValue: selectedFilers?.brand || null,
      toggle: true,
      clear: true,
    },
    {
      name: "inventory_count",
      label: "Inventory Count",
      placeholder: "Filter by inventory count",
      type: "Range",
      defaultValue: selectedFilers?.inventory_count || null,
      toggle: true,
      clear: true,
    },
    {
      name: "order_count",
      label: "Order Count",
      placeholder: "Filter by order count",
      type: "Range",
      defaultValue: selectedFilers?.order_count || null,
      toggle: true,
      clear: true,
    },
    {
      name: "pending_supply_purchased_qty_for_order",
      label: "Pending supply purchased",
      type: "Range",
      defaultValue:
        selectedFilers?.pending_supply_purchased_qty_for_order || null,
      toggle: true,
      clear: true,
    },
    {
      name: "pending_supply_requested_qty_for_order",
      label: "Pending Supply requested",
      type: "Range",
      defaultValue:
        selectedFilers?.pending_supply_requested_qty_for_order || null,
      toggle: true,
      clear: true,
    },
    {
      name: "total_pending_ordered_qty",
      label: "Pending Order",
      type: "Range",
      defaultValue: selectedFilers?.total_pending_ordered_qty || null,
      toggle: true,
      clear: true,
    },
    {
      name: "awaiting_acceptance",
      label: "Awaiting Acceptance",
      placeholder: "Filter by awaiting acceptance",
      type: "Select",
      list: [
        {
          label: "Yes",
          value: "true",
        },
        {
          label: "No",
          value: "false",
        },
      ],
      defaultValue: selectedFilers?.cost || null,
      toggle: true,
      clear: true,
    },
    {
      name: "weight",
      label: "Weight",
      placeholder: "Filter by weight",
      type: "Range",
      defaultValue: selectedFilers?.weight || null,
      toggle: true,
      clear: true,
    },
  ];
};

export default FiltersView;

import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";
import { fetchLoading } from ".";

const fetchShipments = createAsyncThunk(
  "get:api/v1/orders/shipment-labels",
  async (payload, { dispatch, getState }) => {
    if (!payload?.append) {
      dispatch(fetchLoading());
    }
    const data = await api.fetchShipments(
      typeof payload === "string" ? payload : payload.query
    );
    return { ...data?.data, append: payload?.append };
  }
);

const fetchShipmentsFilters = createAsyncThunk(
  "get:api/v1/orders/shipment-labels/filters",
  async (payload, thunkAPI) => {
    const response = await api.fetchShipmentsFilters(payload);
    return response.data;
  }
);

const refreshShipments = createAsyncThunk(
  "get:api/v1/orders/shipment-labels/refresh",
  async (payload, thunkAPI) => {
    try{const response = await api.refreshShipments(payload);
      return response.data;
    }
    catch(e){
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);

const markAsDelivered = createAsyncThunk(
  "post:api/v1/orders/shipment-labels/mark-as-delivered",
  async (payload, thunkAPI) => {
    try {
      const response = await api.markAsDelivered(payload);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);
const markAsVoided = createAsyncThunk(
  "post:api/v1/orders/shipment-labels/mark-as-voided",
  async (payload, thunkAPI) => {
    try {
      const response = await api.markAsVoided(payload);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);

const fetchNotes = createAsyncThunk(
  "get:api/v1/orders/shipment-labels/notes",
  async (payload, thunkAPI) => {
    try {
      const response = await api.fetchNotes(payload);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);

const deleteNote = createAsyncThunk(
  "delete:api/v1/orders/shipment-labels/notes",
  async (payload, thunkAPI) => {
    try {
      const response = await api.deleteNote(payload);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);

const addNote = createAsyncThunk(
  "post:api/v1/orders/shipment-labels/notes",
  async (payload, thunkAPI) => {
    try {
      const response = await api.addNote(payload);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);

export {
  fetchShipments,
  fetchShipmentsFilters,
  fetchNotes,
  deleteNote,
  addNote,
  refreshShipments,
  markAsDelivered,
  markAsVoided,
};

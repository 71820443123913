import { twMerge } from "tailwind-merge";
import {v4 as uuid} from "uuid";
import PropTypes from 'prop-types';

const SecondaryRow = ({
    filteredTheme,
    row,
    rowIndex,
    style = {},
}) => {
  return (
    <>
      {Object.keys(filteredTheme).map(
        (item, index) =>
          !style?.row?.[item]?.td?.includes("hidden") && (
            <div
              key={uuid()}
              className={twMerge(
                ` flex items-center
                                                   !px-0 !py-0 border-b box-border
                                                    ${
                                                      item.startsWith(
                                                        "_actions"
                                                      )
                                                        ? "actionRow"
                                                        : ""
                                                    }
                                                    ${
                                                      item.startsWith("_")
                                                        ? "w-[90px]"
                                                        : ""
                                                    }
                                                    ${
                                                      item.startsWith(
                                                        "_actions"
                                                      ) && style?.hideSelect
                                                        ? "!w-[35px]"
                                                        : ""
                                                    }
                                                    ${
                                                      item === "status"
                                                        ? "w-[90px]"
                                                        : ""
                                                    }
                                                    ${
                                                      style?.row?.td
                                                        ? style?.row?.td
                                                        : ""
                                                    }
                        
                                                `,
                `${style?.row?.[item]?.td ? style?.row?.[item]?.td : ""}`
              )}
            >
              <div
                className={twMerge(
                  `
                                                          align-top py-[30px] text-left min-w-max whitespace-nowrap
                                                          ${
                                                            item.startsWith("_")
                                                              ? "pl-[0px] pr-[0px] w-[90px] mr-[0px] !justify-start py-[0px]"
                                                              : "px-[30px]"
                                                          }
                                                          ${
                                                            item.startsWith(
                                                              "_actions"
                                                            )
                                                              ? "!w-fit !max-w-[90px]"
                                                              : ""
                                                          }
                                                          ${
                                                            item.startsWith(
                                                              "*sm-"
                                                            )
                                                              ? "px-[0px] min-w-max w-fit max-w-fit"
                                                              : ""
                                                          }
                                                          ${
                                                            item.startsWith(
                                                              "*fit-"
                                                            )
                                                              ? "min-w-fit min-w-max w-fit max-w-fit"
                                                              : ""
                                                          }
                                                          ${
                                                            !item.startsWith(
                                                              "_"
                                                            )
                                                              ? style?.row
                                                                  ?.className
                                                              : ""
                                                          }
                                                          ${
                                                            index === 1
                                                              ? "pl-[15px]"
                                                              : ""
                                                          }
                                                      `,
                  style?.row?.[item]?.className
                )}
              >
                {filteredTheme[item]({ ...row}, rowIndex)}
              </div>
            </div>
          )
          )}
    </>
  );
};

export default SecondaryRow;

SecondaryRow.propTypes = {
  filteredTheme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.object
};

import React, { useRef, useState, useEffect } from "react";
import util from "./index";
import Icons from "../../orders/icons";
import PropTypes from "prop-types";

function ExpandableText({
  callBack,
  children,
  maxLines = 1,
  className,
  tooltip = false,
  tooltipClassName = "!max-w-[450px]",
  forcePosition = "bottom",
  hideCopy,
  popUpToggleClassName,
  popUpOuterClassName,
  innerClassName,
  ellipsisClass
}) {
  const innerRef = useRef();
  const [isTruncated, setIsTruncated] = useState(false);

  const checkIfTruncated = () => {
    const element = innerRef.current;
    if (element) {
      const isOverflowing =
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth;
      setIsTruncated(isOverflowing);
    }
  };

  useEffect(() => {
    // Initial check if the text is truncated
    checkIfTruncated();

    // Re-check on window resize
    window.addEventListener("resize", checkIfTruncated);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIfTruncated);
    };
  }, [children, maxLines]);

  const IsTooltip = ({ children, text }) => (
    <>
      {tooltip && isTruncated ? (
        <util.popUp
          toggleClassName={popUpToggleClassName}
          outerClassName={popUpOuterClassName}
          toggle={() => <>{children}</>}
          forcePosition={forcePosition}
          className={`!text-[14px] !font-medium !leading-[21px] ${
            tooltipClassName || ""
          }`}
          action={"hover"}
        >
          {text}
        </util.popUp>
      ) : (
        <>{children}</>
      )}
    </>
  );
  IsTooltip.propTypes = {
    children: PropTypes.node.isRequired, // Children content to render
    text: PropTypes.node.isRequired, // Text content for the tooltip
  };

  return (
    <div className={"relative group w-full flex items-center"}>
      <style>
        {`
                .expandableText:not(.expanded)  {
                    display: -webkit-box;
                    -webkit-line-clamp: ${maxLines};
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    white-space-collapse:preserve ;
                    word-break: break-word;
                    max-height: calc(${maxLines} * 1.4em) !important;
                }

                .ellipsis-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;
                    right: 0;
                    bottom: 25%;
                }

                .ellipsis-button {
                    display: inline-block;
                    padding-right: 4px;
                }
                `}
      </style>
      {children && (
        <IsTooltip text={children}>
          <div
            className={` expandableText w-full ${className} ${
              tooltip ? "tooltip" : ""
            }`}
          >
            <div
              aria-hidden="true"
              onClick={callBack}
              ref={innerRef}
              className={`inner text-left ${innerClassName}  !w-[calc(100%-24px)]`}
            >
              {children}
            </div>

            <div className={`ellipsis-container ${ellipsisClass}`}>
              {/* Copy Icon */}
              {!hideCopy && (
                <button
                  onClick={() => navigator.clipboard.writeText(children)}
                  className="cursor-pointer px-1 pt-1 rounded text-gray-300 hover:text-gray-500 group-active:text-blue-800"
                >
                  <Icons.CopyOrders className="w-[14px] h-[14px]" />
                </button>
              )}
            </div>
          </div>
        </IsTooltip>
      )}
    </div>
  );
}

ExpandableText.propTypes = {
  callBack: PropTypes.func, // A callback function for button click
  children: PropTypes.node.isRequired, // Children to display inside the component
  maxLines: PropTypes.number, // Number of lines before truncation
  className: PropTypes.string, // Additional CSS classes for the wrapper
  tooltip: PropTypes.bool, // Whether to display a tooltip on hover
  tooltipClassName: PropTypes.string, // Additional CSS classes for the tooltip
  forcePosition: PropTypes.string, // Tooltip positioning (e.g., 'bottom', 'top')
  hideCopy: PropTypes.bool, // Hide copy icon
  ellipsisClass: PropTypes.string, 
  innerClassName: PropTypes.string, // Additional CSS classes for the inner content
  popUpToggleClassName: PropTypes.string,
  popUpOuterClassName: PropTypes.string,
};
export default React.memo(ExpandableText);

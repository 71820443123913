import React from "react";

import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import moment from "moment";

import { howLongFromNow } from "../../../utils/helper";
import ShipmentLabel from "../components/table/shipmentLabel";
import Weight from "../components/table/weight";
import Profit from "../components/table/profit";
import Item from "../components/table/item";
import OrderStatus from "../components/table/orderStatus";
import OrderRowAction from "../components/table/orderRowAction";
import SalesChannelStatus from "../components/table/salesChannelStatus";
import OrderId from "../components/table/orderid";
import NumberPriorityColor from "../components/table/numberPriorityColor";
import ShipTo from "../components/table/shipTo";


const statusLabel = (item) => [
  {
    action: "Shipped",
    name: item?.accepted_by?.name,
    date: item?.accpeted_at,
    qty: item?.quantity,
  },
  {
    action: "Dilevered",
    name: item?.purchase?.created_by?.name,
    date: item?.purchase?.created_at,
    qty: item?.purchase?.quantity,
  },
  {
    action: "Created",
    name: item?.purchase?.supply_request?.requester?.name,
    date: item?.purchase?.supply_request?.request_date_time,
    qty: item?.purchase?.supply_request?.qty,
  },
];

const tableView = (
  list,
  callBack,
  isAwaiting,
  updateRow,
  onFilterApply,
  handleRemoveItemFromList,
  pageData
) => {
  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const howMuchTimeLeft = (date) => {
    const now = moment();
    const inputDate = moment(date);
    const difference = inputDate.diff(now, "days");

    if (difference >= 0) {
      return "text-emerald-700"; // Geçmediyse
    } else if (difference > -3) {
      return "text-orange-500"; // 3 güne kadar geçtiyse
    } else {
      return "text-red-700"; // 3 günden fazla geçtiyse
    }
  };

  return {
    list: list,
    theme: {
      control: (item) => (
        <div className={"w-[20px] h-[50px]"}>
          <OrderRowAction
            actions={{
              order_id: item?.id,
              customer_note: item?.customer_note,
              internal_notes: item?.notes_count,
              shipping_content: item?.shipping_content,

              customer_note_enabled: item?.customer_note_enabled,
              internal_notes_enabled: item?.internal_notes_enabled,
              shipping_content_enabled: item?.shipping_content_enabled,
            }}
          />
        </div>
      ),
      status: (item) => (
        <div
          className={
            "w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"
          }
        >
          {item?.cancel_requested || item?.refund_requested ? (
            <span>
              {" "}
              <SalesChannelStatus
                cancel_requested={item?.cancel_requested}
                refund_requested={item?.refund_requested}
              />{" "}
            </span>
          ) : (
            ""
          )}
          <OrderStatus
            isAwaiting={isAwaiting}
            statusId={item?.order_status_id}
            color={item?.order_status_color_code}
            text={item?.order_status}
            item={{
              product_connected: item?.order_status !== 14,
              ready_to_ship: item?.ready_to_ship,
              shipped: item?.shipped,
              is_cancelled: item?.is_cancelled,
              order: item,
            }}
            orderId={item?.id}
            orderHistory={
              <StatusHistory title="Order History" list={statusLabel(item)} />
            }
            callBack={callBack}
          />
        </div>
      ),
      "order date": (item) => (
        <div>
          {item?.order_date ? (
            <div className="  flex-col justify-start gap-1.5 h-[64px] py-2  items-start whitespace-nowrap inline-flex w-[140px] ">
              <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-900 text-sm font-normal leading-[14px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.order_date)).format(
                    "MMM D, YYYY"
                  )}
                </div>
              </div>
              <div className="self-stretch justify-between items-center gap-4 inline-flex">
                <div className="text-gray-600 text-xs font-normal leading-[18px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.order_date)).format("h:mm a")}
                </div>
                <div
                  className={`${howMuchTimeLeft(
                    item?.ship_by_date
                  )} text-xs font-medium leading-[18px] whitespace-nowrap`}
                >
                  {moment(item?.order_date).fromNow() === "a few seconds ago"
                    ? "Just now"
                    : howLongFromNow(item?.order_date, true)}
                </div>
              </div>
            </div>
          ) : (
            <div className=" flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
              -
            </div>
          )}
        </div>
      ),
      "store & order id": (item) => (
        <div
          className={"flex flex-col gap-[0px] relative justify-start  w-full"}
        >
          <OrderId
            callBack={callBack}
            orderId={item.id}
            order_numbers={item.order_numbers}
            order={{
              order_number: item?.order_numbers[0],
              marketplace: item?.sales_channel_account?.channel?.marketplace,
              ...item?.sales_channel_account,
            }}
            item={item}
            onFilterApply={onFilterApply}
            boundFilters={pageData?.boundFilters}
          />
        </div>
      ),

      // image: (item) => (

      // ),

      item: (item) => {
        let imageSrc;
        let isImageMagnifier = false;

        if (item?.items_count === 1) {
          if (item?.first_item?.image_url) {
            imageSrc = item.first_item.image_url;
            isImageMagnifier = true;
          } else {
            imageSrc = "/assets/images/noImage.png";
          }
        } else if (!item?.first_item) {
          imageSrc = "/assets/images/noImage.png";
        } else {
          imageSrc = "/assets/images/multiproduct.png";
        }
        return (
          <div
            className={"w-[100%]  flex flex-row   gap-1 justify-start"}
            style={{ width: "100%" }}
          >
            <div
              className={"w-[56px] h-[64px] justify-center items-center flex"}
            >
              {isImageMagnifier ? (
                <ImageMagnifier src={imageSrc} className={"!max-h-[56px]"} />
              ) : (
                <img alt="s" src={imageSrc} className={"h-[52px] w-[52px]"} />
              )}
            </div>
            <Item
              product={item?.order_items?.filter(
                (item) => +item?.unit_price >= 0
              )}
              callBack={handleRemoveItemFromList}
              orderId={item?.id}
              order={item}
              onFilterApply={onFilterApply}
            />
          </div>
        );
      },

      qty: (item) => {
        let color;

        if (item?.items_total_qty <= 1) {
          color = "gray";
        } else if (item?.items_total_qty <= 5) {
          color = "yellow";
        } else {
          color = "red";
        }
        return (
          <div className={"flex"}>
            <NumberPriorityColor
              number={item?.items_total_qty}
              color={color}
              className={"text-[14px] leading-[21px]"}
            />
          </div>
        );
      },
      weight: (item) => {
        const orderStatusId = item?.order_status_id || null;
        const billableWeight = item?.billable_weight
          ? (+item.billable_weight).toFixed(2)
          : "-";
        const actualWeight = item?.actual_weight
          ? (+item.actual_weight).toFixed(2)
          : "-";
        const dimWeight = item?.dim_weight
          ? (+item.dim_weight).toFixed(2)
          : "-";
        const orderId = item?.id || null;

        // selected ağırlık türünü belirlemek için bağımsız bir ifade
        let selectedWeight;
        if (+actualWeight > +dimWeight) {
          selectedWeight = "actual";
        } else if (+actualWeight < +dimWeight) {
          selectedWeight = "dimensional";
        } else {
          selectedWeight = "actual";
        }
        return (
          <div className={"flex flex-col gap-[0px]"}>
            <Weight
              order_status_id={orderStatusId}
              weight={{
                billable: billableWeight,
                actual: actualWeight,
                dim: dimWeight,
                selected: selectedWeight,
                orderId: orderId,
              }}
            />
          </div>
        );
      },
      profit: (item) => (
        <div className={"flex flex-1 justify-end"}>
            <Profit
              profit={{
                margin: item?.profit_margin,
                profit: item?.profit,
                currency: item?.sales_channel_account?.currency,
                statCalculator: item?.profit_calculations,
                profit_color_code: item?.profit_color_code,
                has_anemic_item: item?.has_anemic_item,
                total: item?.order_total_paid
              }}
            />
        </div>
      ),
      "ship to": (item) => (
        <div className={"flex flex-col gap-[8px] relative !min-w-[141px]"}>
          <ShipTo
            order={item}
            state={item?.ship_to_state}
            name={item?.ship_to_name}
            city={item?.ship_to_city}
            country={item?.ship_to_country}
            customerOrderCount={item?.customer_order_count}
            callBack={callBack}
            order_address_id={item?.order_address_id}
          />
        </div>
      ),

      "tracking id": (item) => (
        <div className={"flex  justify-start !w-full flex-1"}>
          <ShipmentLabel
            shipment={{
              shipment_labels: item?.shipment_labels,
              suggested_shipping_cost: item?.suggested_shipping_cost,
            }}
            orderItem={item}
            callBack={callBack}
            updateRow={updateRow}
          />
        </div>
      ),
    },
  };
};
export default tableView;

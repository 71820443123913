import { createSlice } from "@reduxjs/toolkit";
import { updateBoundFilters } from "../updateBoundFilters";
import updateObjectByPath from "./../../components/dynamic-form/util/updateState";
import {
  addNote,
  apiActions,
  apiDetailActions,
  bulkDeleteOrders,
  bulkRestoreOrders,
  createOrder,
  createShipment,
  createSupplyRequest,
  createSupplyRequestOrderItem,
  deleteNote,
  deleteOrder,
  fetchNotes,
  fetchOrders,
  fetchOrdersFilters,
  fetchShipmentCarrier,
  fetchSingleOrder,
  updateBillableWeight,
  viewOrder,
} from "./thunk";

const initialState = {
  orders: [],
  order: {},
  links: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
    limit: 20,
  },

  filtersMeta: {},
  boundFilters: {},
  meta: {},
  Form: {
    order_id: "",
    account: "",
    sales_channel: "",
    order_date: "",
    ship_by_date: "",
    shipping_paid: "",
    currency: "",
    order_items: [],
    recipient: {},
  },
  formErrors: {},
  shipmentCarrier: {
    status: "idle",
  },
  filterLoading: "idle",
};

export const ordersSlice = createSlice({
  name: "ordersData",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateMeta: (state, action) => {
      state.meta = action.payload;
    },
    // create a reducer to control the input change in the form
    handleInputChange: (state, action) => {
      state.Form[action.payload.name] = action.payload.value;
    },
    addProduct: (state, action) => {
      if (!Array.isArray(state.Form.order_items)) {
        state.Form.order_items = []; // initialize order_items as an empty array
      }
      state.Form.order_items.push(action.payload);
    },
    handleRecipient: (state, action) => {
      state.Form.recipient = action.payload;
    },
    clearForm: (state, action) => {
      state.Form = initialState.Form;
    },
    setSummary: (state, action) => {
      state.Form.summary = action.payload;
    },
    setFormError: (state, action) => {
      state.formErrors = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchOrders.pending, (state, action) => {
      //     state.loading = "pending";
      // })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = "idle";
        state.orders = action.payload?.append
          ? [...state.orders, ...action.payload?.data?.data]
          : action.payload?.data?.data;
        state.links = action.payload?.data?.links;
        state.meta = action.payload?.data?.meta;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(viewOrder.fulfilled, (state, action) => {
        state.order = action.payload?.data;
      })
      .addCase(fetchOrdersFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
        state.filterLoading = "idle";
      })
      .addCase(fetchOrdersFilters.pending, (state, action) => {
        state.filtersMeta = action.payload;
        state.filterLoading = "pending";
      })

      // Fetch Shipment Carrier
      .addCase(fetchShipmentCarrier.pending, (state, action) => {
        state.shipmentCarrier = { ...state.shipmentCarrier, status: "pending" };
      })
      .addCase(fetchShipmentCarrier.rejected, (state, action) => {
        state.shipmentCarrier = { ...state.shipmentCarrier, status: "idle" };
      })
      .addCase(fetchShipmentCarrier.fulfilled, (state, action) => {
        const result = action.payload;
        state.shipmentCarrier = {
          ...state.shipmentCarrier,
          status: "idle",
          [result?.orderId]: {
            ...state.shipmentCarrier[result?.orderId],
            [result?.warehouseId]: result?.data,
          },
        };
      });
  },
});

export const {
  updateRow,
  updateFilter,
  handleInputChange,
  addProduct,
  clearForm,
  handleRecipient,
  setFormError,
  updateNotes,
  updateBoundFilter,
  fetchLoading,
  updateMeta
} = ordersSlice.actions;
export default ordersSlice.reducer;
export {
  fetchOrders,
  fetchSingleOrder,
  viewOrder,
  fetchOrdersFilters,
  createOrder,
  bulkDeleteOrders,
  bulkRestoreOrders,
  deleteOrder,
  apiActions,
  apiDetailActions,
  fetchShipmentCarrier,
  createShipment, //Shipment, carrier
  addNote,
  fetchNotes,
  deleteNote, // Notes
  updateBillableWeight, //Weight
  createSupplyRequest,
  createSupplyRequestOrderItem, // Supply request
};

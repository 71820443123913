// DropDownMenu.js
// -----------------------------------------

// Import General libraries
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Main Component: DropDownMenu
function DropDownMenu({ item, toggleButton, itemMenu, selected }) {
  // Initial State to handle menu open and close.
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click is outside of the modal, close it
        setIsOpen(false); // Call the provided function to close the modal
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Make sure to include onClose in the dependencies

  // Initial Ref to handle menu position.
  const toggleButtonRef = useRef();
  const menuRef = useRef();

  // Handle menu position when menu is open.
  useEffect(() => {
    if (isOpen && menuRef.current && toggleButtonRef.current) {
      // Get position of toggle button and menu
      const toggleButtonRect = toggleButtonRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();

      // Get viewport width and height
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Position based on toggle button's location (similar to ClickDropDown but with toggle position)
      let position = {
        top: toggleButtonRect.bottom,
        left: toggleButtonRect.left,
      };

      // Adjust position to keep the menu within the viewport
      if (position.left + menuRect.width > viewportWidth) {
        position.left = Math.max(
          0,
          position.left - (position.left + menuRect.width - viewportWidth)
        );
      }

      if (position.top + menuRect.height > viewportHeight) {
        position.top = Math.max(
          0,
          position.top - (position.top + menuRect.height - viewportHeight)
        );
      }

      // Set position of menu
      menuRef.current.style.top = `${position.top}px`;
      menuRef.current.style.left = `${position.left}px`;

      // Position the menu absolutely on the page rather than relative to its parent
      menuRef.current.style.position = "fixed";
    }
  }, [isOpen]);

    // Render DropDownMenu
    return (
        <div className="relative" ref={modalRef}>


            {/* Render Toggle Button from props and send isOpen state to handle style. */}
            <div onClick={handleToggle} ref={toggleButtonRef}>
                {toggleButton(isOpen || false)}
            </div>


            {/* Render Menu from itemMenu. */}
            <div ref={menuRef}
                 className={` ${!isOpen && "hidden"} ${itemMenu?.list?.length!==0 && "p-1 transition absolute w-max left-[5px] top-[25px] z-[99999] flex flex-col text-left rounded-[10px] shadow-xl bg-white w-content overflow-auto max-h-[500px]"}`}>
                {itemMenu?.list?.map((menu, index) => (
                    <div
                        key={index}
                        className={
                            // Handle selected item Style.
                            `${selected && (selected?.startsWith("-") ? selected?.substring(1) : selected) === menu.value && "bg-blue-500 hover:bg-blue-600 text-white hover:text-white"}
                                cursor-pointer justify-start font-medium min-w-full hover:bg-gray-100 pl-[16px] pr-12 py-[9px] rounded-[6px] text-gray-500  hover:text-gray-800 transition text-sm`}
                        onClick={() => {
                            handleToggle();
                            menu.onClick(item)
                            /*if (ver === 2){
                                menu.onClick({data:item})
                            } else {
                                menu.onClick(item)
                            }*/
            }}
          >
            {menu.label}
          </div>
        ))}
      </div>
    </div>
  );
}

DropDownMenu.propTypes = {
  item: PropTypes.object,
  toggleButton: PropTypes.func,
  itemMenu: PropTypes.object,
  selected: PropTypes.string,
};

export default DropDownMenu;

import GoodsAcceptanceListItem from "./goodsAcceptanceListItem";

const GoodsAcceptanceList = ({
                                 items,
                                 handleInputChange,
                                 handleDuplicate,
                                 handleRemove,
                                 errors,
                             }) => {
    return (
        <div
            style={{
                height: "calc(100vh - 257px)",
                boxSizing: "border-box",
                width: "fit-content",
                minWidth: "100%",
            }}
            className={`h-full`}
        >
            {items?.map((item, index) => (
                <div className="px-[20px] py-[10px] mb-2 w-full" key={item.id}>
                    <GoodsAcceptanceListItem
                        item={item}
                        first={index === 0}
                        items={items}
                        handleInputChange={handleInputChange}
                        handleDuplicate={handleDuplicate}
                        handleRemove={handleRemove}
                        errors={errors}
                    />
                </div>
            ))}
            {!items ||
                (items?.length === 0 && (
                    <div className="w-full p-[16px] box-border">
                        <div className="">
                            <div
                                className={`flex flex-col w-[calc(100vw-32px)] text-center py-[50px] rounded-2xl items-center `}
                            >
                                <h1 className="text-3xl text-gray-400 mb-6">No Data Found!</h1>
                                <p className="w-[700px] text-gray-400 font-medium">
                                    Please adjust your search and/or filter settings to find data
                                    that matches your criteria.
                                    <br/>
                                    If you haven't set any filters yet and still don't see any
                                    data, it's possible that no records exist in the system.
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default GoodsAcceptanceList;

import React, { useEffect } from "react";
import quantityHandler from "../../../utils/quantityHandler";
import { FiLoader } from "react-icons/fi";
import Button from "../../dynamic-form/elements/Button";
import PropTypes from "prop-types";
import Icons from "../../orders/icons";
import Select from "react-select";
import { v4 as uuidv4 } from 'uuid';
const FooterPagination = ({
  meta,
  onPage,
  onLimit,
  loading = "idle",
  resultsText,
}) => {
  const [innerMera, setInnerMeta] = React.useState(meta);
  const [wait, setWait] = React.useState(true);
  useEffect(() => {
    setInnerMeta(meta);
    setWait(false);
  }, [meta]);

  let Pagination = {
    from: innerMera?.from || 0,
    to: innerMera?.to || 0,
    total: innerMera?.total || 0,
    current_page: innerMera?.current_page || 1,
    last_page: innerMera?.last_page || 1,
  };

  const handlePageChange = (pageNumber) => {
    setWait(true);
    setInnerMeta({ ...innerMera, current_page: pageNumber });
  };

  const getPaginationPages = (current, last) => {
    if (last <= 5) {
      return Array.from({ length: last }, (_, index) => index + 1);
    }

    if (current <= 3) {
      return [1, 2, 3, "...", last];
    } else if (current >= last - 2) {
      return [1, "...", last - 2, last - 1, last];
    } else {
      return [1, "...", current, "...", last];
    }
  };

  Pagination.totalPages = getPaginationPages(
    Pagination.current_page,
    Pagination.last_page
  );

  const resultsLowerCase = resultsText?.toLowerCase()


  return (
    <div
      className={
        "flex items-center gap-2.5 px-[16px] h-[56px] fixed bottom-0 left-0 w-full shadow bg-white z-4"
      }
    >
      <div className="flex items-center w-full">
        <div className="flex items-center  text-base font-normal text-gray-900">
          © 2025 Arbitbox.{" "}
          <span className="text-gray-400"> &nbsp; All rights reserved.</span>
        </div>
      </div>

      <p className="flex justify-center text-[16px] text-gray-900 font-medium w-full">
        <span className={"text-gray-400"}>Showing</span>&nbsp;{quantityHandler(Pagination.from)}
        <span className={"text-gray-900"}>-</span>
        {quantityHandler(Pagination.to)}
        &nbsp;<span className={"text-gray-400"}>of</span>&nbsp;
        {quantityHandler(Pagination.total)} {resultsLowerCase || "results"}
      </p>

      <div className="flex gap-8 justify-end items-center w-full">
        {loading && loading !== "idle" ? (
          <FiLoader className="animate-spin h-6 w-6 text-gray-500" />
        ) : (
          <p
            className={
              "text-gray-500 pagination flex flex-row w-[54px] items-center gap-[10px]"
            }
          >
            <style>
              {`
                          .pagination .select__indicator-separator.css-1u9des2-indicatorSeparator,      .pagination .select__indicator-separator.css-1u9des2-indicatorSeparator { display: none; }
                          .pagination .css-1xc3v61-indicatorContainer,      .pagination .css-15lsz6c-indicatorContainer { padding-left: 0px; padding-right: 0px; }
                          .pagination .css-1xc3v61-indicatorContainer svg,  .pagination .css-15lsz6c-indicatorContainer svg { color: transparent !important; background: url('/assets/images/arrow.svg'); width: 9px; height: 9px; opacity: 0.7; }
                          .pagination label { display: none; }
                          .pagination .css-6nft0r-menu { bottom: 8px; }
                          .pagination .css-1fdsijx-ValueContainer { width: 30px !important; padding: 0px !important; } .pagination .css-1dimb5e-singleValue { text-overflow: unset !important; }
                          
                        `}
            </style>
            <Select
              className="basic-single"
                classNamePrefix="select"
                defaultValue={(Pagination.to - (Pagination.from - 1)).toString()}
                name="color"
                isSearchable={false}
                value={[
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" },
                  { value: "200", label: "200" },
                ].find((item) => item.value == meta?.limit)}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    height: "21px",
                    minHeight: "auto",
                    border: "none !important",
                    background: "none",
                    fontSize: "14px",
                    fontWeight: "600",
                    outline: state.isFocused ? "none !important" : "none",
                    boxShadow: state.isFocused ? "none !important" : "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    whiteSpace: "wrap",
                    width: "100px",
                  }),
                  container: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  indicatorSeparator: (provided) => ({
                    // ...provided,
                    display: "none",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                    color: state.isSelected ? "white" : "black",
                    "&:hover": {
                      backgroundColor: state.isSelected ? "#00A3FF" : "#b2d4ffb8",
                      color: state.isSelected ? "white" : "black",
                    },
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0 !important'
                  }),
                }}
                menuPlacement="top"
                onChange={(data) => { 
                  onLimit(data.value);
                }}
              options={[
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" },
                { value: "200", label: "200" },
              ]}
            />
          </p>
        )}

        <div
          className={`!gap-[-1px] flex-row flex ${
            wait || loading === "pending" ? "opacity-50" : ""
          }`}
        >
          <Button
            item={{
              label: <Icons.ChevronRight className="h-3 w-3 rotate-180" />,
              buttonType: "icon",
              className: `!border-[#D1D5DB] !text-gray-400  ${
                Pagination.current_page > 1 && !wait
                  ? " hover:!border-gray-400 hover:!text-gray-500 hover:z-20"
                  : "opacity-50"
              } !px-3 !py-1.5 !m-0 !h-[32px] !leading-[21px] text-[14px] font-medium !rounded-l-[4px] !rounded-r-[0px] `,
              disabled: wait || Pagination.current_page <= 1,
            }}
            onClick={() => {
              if (!wait) {
                onPage(Pagination.current_page - 1);
                handlePageChange(Pagination.current_page - 1);
              }
            }}
          />
          {Pagination?.totalPages.map((val, i) => (
            <Button
              key={uuidv4()}
              item={{
                label: val,
                buttonType: "icon",
                className: `${
                  Pagination?.current_page === val
                    ? "bg-blue-100 !text-[#1C64F2]"
                    : ""
                } !border-[#D1D5DB] !text-gray-400 ${
                  val !== "..." &&
                  Pagination?.current_page !== val &&
                  onPage &&
                  !wait
                    ? "hover:!border-gray-400 hover:!text-gray-500 hover:z-20"
                    : ""
                } !px-3 !py-1.5 !m-0 !h-[32px]  leading-[21px] text-[14px] font-medium !rounded-[0px] ml-[-1px] `,
                disabled:
                  wait ||
                  val === "..." ||
                  Pagination?.current_page === val ||
                  !onPage,
              }}
              onClick={() => {
                if (onPage && !wait) {
                  onPage(val);
                  handlePageChange(val);
                }
              }}
            />
          ))}

          <Button
            item={{
              label: <Icons.ChevronRight className="h-3 w-3" />,
              buttonType: "icon",
              className: `!border-[#D1D5DB] !text-gray-400 ${
                Pagination.last_page > Pagination.current_page && !wait
                  ? " hover:!border-gray-400 hover:!text-gray-500 hover:z-20"
                  : "opacity-50"
              } !px-3 !py-1.5 !m-0 !h-[32px]  leading-[21px] text-[14px] font-medium !rounded-r-[4px] !rounded-l-[0px] ml-[-1px] `,
              disabled: wait || Pagination.current_page >= Pagination.last_page,
            }}
            onClick={() => {
              if (!wait) {
                onPage(Pagination.current_page + 1);
                handlePageChange(Pagination.current_page + 1);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

FooterPagination.propTypes = {
  meta: PropTypes.object,
  onPage: PropTypes.func,
  onLimit: PropTypes.func,
  loading: PropTypes.string,
  resultsText: PropTypes.string,
};

export default FooterPagination;

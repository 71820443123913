import React, { useEffect, useState } from "react";
import DynamicTable from "../../../../../dynamic-page";
import PropTypes from "prop-types";

import tableView from "./../model-view/table";
import mapData from "./../model-view/mapData";
import Icons from "../../../../icons";
import updateState from "../../../../../dynamic-form/util/updateState";

import { useDispatch } from "react-redux";
import {
  apiActions,
  fetchSingleOrder,
} from "../../../../../../store/orders";
import util from "../../../../../dynamic-page/util";

import updateOrderItemRow from "../util/updateOrderItemRow";
import prepareDataToAddItem from "../util/prepareDataToAddItem";
import prepareDataToViewItem from "../util/prepareDataToViewItem";
import callEndpoint from "../../../../../dynamic-page/util/callEndpoint";

const ProductList = ({
  orderItems,
  actionButton,
  actionCallBack,
  actionEditCallBack,
  actionAddProduct,
  orderId,
  isView,
  is_manual,
  update,
}) => {
  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteList, setDeleteList] = useState([]);

  const dispatch = useDispatch();

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    const checkLocalAndView = (item, mode) =>
      item
        ? item?._data?.isView === mode
          ? [item.id]
          : []
        : selectedRow
            .filter((item) => {
              return item?._data?.isView === mode;
            })
            .map((item) => item.id);
    const localItemId = checkLocalAndView(item);
    const viewItemId = checkLocalAndView(item, true);

    setSelectedRow([]);
    return { local: localItemId, view: viewItemId };
  };
  const handleCallApiInAction = (action, item, func, method = null) => {
    dispatch(
      apiActions({
        data: { orders: giveSelectedItemId(item) },
        action: action,
        method: method,
      })
    ).then((result) => {
      func(result);
    });
  };

  const handleUpdateList = (data) => {
    let newArray = { new: list };
    newArray = updateState(newArray, `new.[id:${data.id}]`, data);
    actionCallBack(newArray.new);
  };

  useEffect(() => {
    actionButton(actions(selectedRow));
  }, [selectedRow]);

  const handleFetchAndUpdateItem = (
    orderId,
    itemId,
    notification,
    callBack
  ) => {
    updateOrderItemRow(orderId, itemId).then((rs) => {
      let newArray = { new: list };
      newArray = updateState(newArray, `new.[id:${itemId}]`, rs);
      actionCallBack(newArray.new);
      util.notifier(notification);
      dispatch(fetchSingleOrder(orderId)).then((rs) => {
        callBack && callBack(rs?.payload?.data?.[0]);
      });
    });
  };

  const actionList = {
    editItem: {
      label: (
        <div className={"flex flex-row gap-[10px]"}>
          <Icons.edit className={"w-[15px]"} />
          Edit Item
        </div>
      ),
      onClick: (item) => {
        const updateRow = (data) => {
          let newArray = { new: list };
          newArray = updateState(newArray, `new.[id:${data.id}]`, data);
          actionCallBack(newArray.new);
        };
        if (!isView) updateRow(item);
        actionEditCallBack({
          item: item,
          callBack: async (data) => {
            if (data) {
              if (data?._data?.isView) {
                const mapData = await prepareDataToAddItem(data);
                const orderId = data._data.order_id;
                dispatch(
                  apiActions({
                    data: mapData,
                    action: "order-item",
                    method: "POST",
                    orderId: orderId,
                    itemId: data.id,
                  })
                ).then((result) => {
                  util.notifier(result?.payload?.data);
                  if (result?.payload?.data?.type === "success") {
                    updateRow(
                      prepareDataToViewItem(result?.payload?.data?.data)
                    );
                  }
                });
              } else {
                updateRow(data);
              }
            }
            //actionEditCallBack(null)
          },
        });
      },
      bulkDisable: true,
    },
    removeItem: {
      label: "Remove Item",
      onClick: (data) => {
        const changelist = giveSelectedItemId(data);
        const deleteLocal = (items) => {
          let newArray = { new: list };
          items.map((i, index) => {
            newArray = updateState(newArray, `new.[id:${i}]`);
          });
          actionCallBack(newArray.new);
        };

        if (changelist.view.length > 0) {
          const orderId = list.find((item) => item.id === changelist.view[0])
            ._data.order_id;
          callEndpoint(
            {
              title: "Remove Order Item",
              url: `orders/${orderId}/order-item`,
              data: { order_items: changelist.view },
              method: "DELETE",
              pureData: true,
            }
          ).then((result) => {
            if (result?.type === "success") {
              deleteLocal([...changelist.local, ...changelist.view]);
              update(orderId);
            }
          }).catch((error) => {
            console.log(error);
          });
        } else {
          deleteLocal(changelist.local);
        }
      },
    },
    connectProduct: {
      label: "Connect Product",
      onClick: (data) => {
        if (data?._data?.isView) {
          const product_id = data?.arbitbox_product_id;
          const connectionAction = product_id
            ? "connect-product"
            : "disconnect-product";
          dispatch(
            apiActions({
              data: { product_id: product_id, sku: data?.sku },
              action: connectionAction,
              orderId: `${data?._data.order_id}/${data?.id}`,
            })
          ).then((result) => {
            //util.notifier(result?.payload?.data)
            handleFetchAndUpdateItem(
              data._data.order_id,
              data.id,
              result?.payload?.data,
              update
            );
            // if (result?.payload?.data?.type === "success") {
            //
            // }
          });
        } else {
          handleUpdateList(data);
        }
      },
    },

    disconnectProduct: {
      label: "Disconnect Product",
      onClick: (data) => {
        const product_id = data?.arbitbox_product_id;
        const connectionAction = "disconnect-product";
        dispatch(
          apiActions({
            data: { product_id: product_id, sku: data?.sku },
            action: connectionAction,
            orderId: `${data?._data.order_id}/${data?.id}`,
          })
        ).then((result) => {
          //util.notifier(result?.payload?.data)
          handleFetchAndUpdateItem(
            data._data.order_id,
            data.id,
            result?.payload?.data,
            update
          );
          if (result?.payload?.data?.type === "success") {
            //handleFetchAndUpdateItem(item._data.order_id, item.id, result?.payload?.data)
            //handleUpdateList(data)
          }
        });
      },
    },
  };


  useEffect(() => {
    setList(mapData(orderItems));
  }, [orderItems]);

  const actions = (row) => {
    return {list: [actionList.removeItem]}
  };
  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const handleActions = (data) => {
    actionList[data.action].onClick(data.data);
  };

  return (
    <>
      {list && list.length > 0 && (
        <DynamicTable
          view={tableView}
          data={tableView(list, handleActions, isView, is_manual)}
          actions={actions}
          loading="idle"
          selectedRow={selectedRow}
          onSelect={onSelectRow}
          style={
            isView
              ? {
                  hideSelect: true,
                  outerContainer: "!overflow-visible",
                  table: {
                    style: { width: "100%", minWidth: "100%", height: "auto" },
                  },
                  tbody: {
                    className: "border-t border-gray-200 mx-[16px] block",
                  },
                  row: {
                    className:
                      "!px-[8px] !py-[10px] !items-center !justify-center flex text-[12px] flex flex-1 min-h-[120px]",
                    image: {
                      td: "!w-[120px]",
                    },
                    qty: {
                      td: "!w-[120px]",
                    },
                    item: {
                      td: "!w-[100%] ",
                      className:
                        "!box-border !w-[100%] !justify-start !whitespace-normal  !min-w-[410px]",
                    },
                  },
                  header: {
                    th: "hidden",
                  },
                  noData: {
                    className: "hidden",
                  },
                }
              : {
                  outerContainer: "flex-1",
                  table: {
                    style: {
                      width: "100%",
                      minWidth: "100%",
                      height: "100%",
                      zIndex: 0,
                    },
                    className: "overflow-auto",
                  },
                  // tbody: {
                  //     className: "border-t border-gray-200 mx-[16px] block"
                  // },
                  header: {
                    className: "!justify-center !py-4",
                    delete: { className: "hidden" },
                    _actions: { td: "hidden", className: "hidden" },
                    image: { className: "hidden" },
                  },
                  row: {
                    className:
                      "!px-[8px] !py-[10px] !items-center !justify-center flex text-[12px] flex flex-1 h-[54px]",
                    _actions: { className: "!py-[0px] hidden" },
                    delete: { className: "!py-[0px]" },
                    item: {
                      className: "!py-0",
                    },
                  },
                  noData: {
                    className: "hidden",
                  },
                }
          }
        />
      )}
      <style>
        {`
                    @keyframes deleteAnimation {
                        0% {   opacity: 100% }
                        100% {  opacity: 0% }
                    }
                `}
        {deleteList.length > 0 &&
          deleteList.map((i, index) => {
            setTimeout(function () {
              setDeleteList(
                updateState({ new: deleteList }, `new.[id:${i.id}]`)
              );
            }, 2000);
            return `
                        #dynamicTableRow_${i.id} td > div{
                            animation: deleteAnimation 0.5s linear forwards; overflow: hidden;
                        }
                    `;
          })}
      </style>
    </>
  );
};

ProductList.propTypes = {
  orderItems: PropTypes.array,
  actionButton: PropTypes.func,
  actionCallBack: PropTypes.func,
  actionEditCallBack: PropTypes.func,
  actionAddProduct: PropTypes.func,
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isView: PropTypes.bool,
  is_manual: PropTypes.bool,
  update: PropTypes.func,
};

export default ProductList;

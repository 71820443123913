import { createSlice } from "@reduxjs/toolkit";

import {
  addNote,
  deleteNote,
  fetchNotes,
  fetchReturns,
  fetchReturnsFilters,
  apiActions
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  returns: [],
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  filters: {},
};

export const returnsSlice = createSlice({
  name: "returns",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    // fetchOffer: (state, action) => {
    //   return { ...state, offers: action.payload };
    // },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReturns.fulfilled, (state, action) => {
        state.loading = "idle";
        state.returns = action.payload?.append
          ? [...state.returns, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchReturns.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchReturnsFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  extraReducers,
  updateRow,
  fetchLoading,
} = returnsSlice.actions;

export { fetchReturns, fetchReturnsFilters, fetchNotes, deleteNote, addNote , apiActions };

export default returnsSlice.reducer;

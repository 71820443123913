import { Navigate } from "react-router-dom";
import ProductComponent from "../components/product";
import TableSalesListings from "../components/sales-listing";
import SigninPage from "../components/SigninPage";
import SignupPage from "../components/SignupPage";
import ProductSummary from "../components/products-summary";
import ProductList from "../components/product-list";
import Layout from "../components/sub-elements/layout";
import TargetSellerListings from "../components/target-seller-listing";
import Accounts from "../components/accounts";
import Categories from "../components/categories";
import Offers from "../components/offer";
import Supplies from "../components/Supplies";
import Users from "../components/users";
import Currencies from "../components/currencies";
import Brands from "../components/brands";
import SupplyPurchase from "../components/supply-purchase";
import InventoryAdjustments from "../components/inventory-adjustments";
import GoodsAcceptance from "../components/goods-acceptance";
import Units from "../components/units";
import Attributes from "../components/attributes";
import Carriers from "../components/carrier";

import Orders from "../components/orders";
import Notifications from "../components/notifications";
import Shipments from "../components/shipments";

import PrintPage from "../components/print-page";
import AmazonCallback from "../components/accounts/amazon-callback";
import TargetSellerFolder from "../components/target-seller-folder";
import GoodsAcceptanceNew from "../components/goods-acceptance-new";
import FcRestocking from "../components/fc-restocking";
import Returns from "../components/returns";
import Dashboard from "../components/dashboard";
import RepricerListings from "../components/repricer_listings";
import Warehouses from "../components/warehouses";
import SupplyChannels from "../components/supply_channels";
import SupplyChannelListings from "../components/supply-channel-listings";
import SettingsPage from "../components/settings-page";
import ShippingCost from "../components/shipping-cost-page";
import InventoryPurchases from "../components/inventory-purchases";
import PickupPoints from "../components/pickup-points";
import Transfers from "../components/transfers";

export const routes = (isLogin) => [
  {
    path: "/",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "*",
    element: <>Page not found</>,
  },
  {
    path: "/login",
    element: !isLogin ? (
      <SigninPage />
    ) : (
      <Navigate to="/growth/opportunities" />
    ),
  },
  {
    path: "/register",
    element: <SignupPage />,
  },
  {
    path: "/print/:url",
    element: <PrintPage />,
  },
  {
    path: "/stores/authorization/callback",
    element: <AmazonCallback />,
  },
  {
    path: "/growth",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: "target_seller_folders",
        element: <TargetSellerListings />,
      },
      {
        path: "target_seller_folders/:id/:type",
        element: <TargetSellerFolder />,
      },
    ],
  },
  {
    path: "/product",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: ":id",
        element: <ProductComponent />,
      },
      {
        path: "summary",
        element: <ProductSummary />,
      },

      {
        path: "brands",
        element: <Brands />,
      },
      {
        path: "list",
        element: <ProductList />,
      },
    ],
  },
  {
    path: "/logistics",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "transfers",
        element: <Transfers />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },

      {
        path: "shipping-costs",
        element: <ShippingCost />,
      },
      {
        path: "shipments",
        element: <Shipments />,
      },
      {
        path: "returns",
        element: <Returns />,
      },
    ],
  },
  {
    path: "/listing",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: "offers",
        element: <Offers />,
      },
      {
        path: "sales_listings",
        element: <TableSalesListings />,
      },
      {
        path: "automate_repricing",
        element: <RepricerListings />,
      },
      {
        path: "supply_channel_listings",
        element: <SupplyChannelListings />,
      },
    ],
  },
  {
    path: "/settings",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: "carriers",
        element: <Carriers />,
      },
      {
        path: "supply_channels",
        element: <SupplyChannels />,
      },
      {
        path: "warehouses",
        element: <Warehouses />,
      },
      {
        path: "pickup_points",
        element: <PickupPoints />,
      },
      {
        path: "sales_channel_accounts",
        element: <Accounts />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "pages",
        element: <SettingsPage />,
      },
      {
        path: "attributes",
        element: <Attributes />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "currencies",
        element: <Currencies />,
      },
      {
        path: "units",
        element: <Units />,
      },
    ],
  },
  {
    path: "/inventory",
    element: isLogin ? <Layout /> : <Navigate to="/login" />,
    children: [
      {
        path: "inventory_adjustments",
        element: <InventoryAdjustments />,
      },
      {
        path: "inventory_purchases",
        element: <InventoryPurchases />,
      },
      {
        path: "supply_requests",
        element: <Supplies />,
      },
      {
        path: "supply_purchases",
        element: <SupplyPurchase />,
      },
      {
        path: "goods_acceptance/new",
        element: <GoodsAcceptanceNew />,
      },
      {
        path: "goods_acceptance",
        element: <GoodsAcceptance />,
      },
      {
        path: "fc_restocking",
        element: <FcRestocking />,
      },
    ],
  },
];

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
import ConnectForm from "../sales-listing/modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  fetchSalesListings as fetchData,
  fetchSalesListingsFilter as fetchFilters,
  updateBoundFilter,
  updateFilter,
  updateSalesListingFulfilment,
  updateRow,
  updateMeta,
} from "../../store/sales-listings";

import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import notifier from "../dynamic-page/util/notifier";
import { fetchSalesListing } from "../../store/sales-listings/thunk";

import {
  closeNotification,
  startNotification,
} from "../dynamic-page/util/notifierLive";
import { refreshAccountListings } from "../../store/fc-restocking";
import ListingDetailModal from "../../components/listing-detail-modal";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import Icons from "../orders/icons";
import { useLocation } from "react-router-dom";
import Button from "../dynamic-form/elements/Button";
import PropTypes from "prop-types";
import FormModal from "../dynamic-page/FormModal";
import customRuleFormView from "./modals-view/customRuleForm";
import axios from "axios";

import { Tooltip } from "react-tooltip";
import shortenString from "../dynamic-page/util/shortenString";
import PriceHistoryModal from "./modals-view/priceHistory";
import moment from "moment";

export const CustomRuleTooltip = () => (
  <span className="">
    <Icons.Info
      className="w-5 h-5  text-gray-300"
      data-tooltip-id={`CustomRuleTooltip`}
    />
    <Tooltip
      id={`CustomRuleTooltip`}
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
        padding: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col items-start gap-2 py-2.5 px-3 w-[270px]">
        <span className="text-[14px] leading-[14px] font-medium text-gray-900">
          test
        </span>
        <span className="text-gray-500 text-start text-[12px] leading-[15px] font-normal whitespace-pre-wrap">
          {`test`}
        </span>
      </div>
    </Tooltip>
  </span>
);

const RepricerListings = ({
  product_id = null,
  displayTitle = "flex",
  displayTabGroups = "flex",
  showCreateForm = false,
  showSalesModal,
  hideFilter,
  setShowSalesModal,
  updateListingsCount,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Initial page at first load.

  const [defaultSettings, setDefaultSettings] = useState(null);
  useEffect(() => {
    let filters = {
      available: 1,
    };

    if (product_id) {
      filters = { product: product_id };
    }

    async function getRepricerDefaultSettings() {
      try {
        const res = await axios.get("/api/v1/repricer/default-settings");

        setDefaultSettings(res?.data);
      } catch (err) {
        console.log(err);
      }
    }
    getRepricerDefaultSettings();

    const startPageByFilters = {
      filters: filters,
      sort: "",
      page: 1,
      limit: 20,
      search: "",
      includes: "repricer,account.channel",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters, [
        "filter[order_status]",
        "filter[containingProduct]",
        "filter[type]",
      ])
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.salesListing);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setSalesModalListingData] = useState(null); // State to store salesListing data
  const [visiblePriceHistoryModal, setVisiblePriceHistoryModal] =
    useState(false);

  const openListingModal = (modalListing) => {
    setSalesModalListingData(modalListing); // Set salesListing data in the state
    setVisibleListingModal(true);
  };

  const openPriceHistoryModal = (modalListing) => {
    setSalesModalListingData(modalListing);
    setVisiblePriceHistoryModal(modalListing);
  };

  const [formData, setFormData] = useState(null);

  const [connectFormData, setConnectFormData] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);

  // Handle order details modal.

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const [repricerModalVisibility, setRepricerModalVisibility] = useState(false);

  const [modalTitleName, setModalTitleName] = useState("");

  const [singleData, setSingleData] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const actionList = {
    ruleSettings: {
      label: "Rule Setting",
      onClick: (row) => {
        setFormData({
          ...row?.repricer,
        });
        setSingleData([row]);
        let name = shortenString(
          row?.sales_channel_listing?.name ||
            (selectedRow?.length === 1 &&
              selectedRow?.[0]?.sales_channel_listing?.name),
          45,
          false,
          true,
          null,
          "Custom Repricing Rule for "
        );

        setModalTitleName(name || "Custom Rule Settings");

        setRepricerModalVisibility(true);
      },
    },
    toggle: {
      label: "Toggle",
      onClick: (row) => {
        if (!row?.repricer) {
          actionList.ruleSettings.onClick(row);
        } else {
          const temp = Object.fromEntries(
            Object.entries({ ...row.repricer }).filter(
              ([key, value]) =>
                value !== null &&
                ["created_at", "status", "updated_at"].indexOf(key) === -1
            )
          );
          callEndpoint({
            title: `Updating Listing Repricer`,
            url: `account-listings/repricer/upsert`,
            method: "POST",
            data: {
              listings: [
                {
                  account_listing_id: row.id,
                  ...temp,
                },
              ],
            },
            pureData: true,
          }).then((res) => {
            if (res.type === "success") {
              const ids = [row?.id];
              pageData?.filters?.filters?.has_repricer == null
                ? updateRows(ids, res)
                : deleteRows(ids, res?.data);
            }
          });
        }
      },
    },
    passivate: {
      label: "Passivate Repricer(s)",
      onClick: (row) => {
        const ids = row?.id ? [row.id] : selectedRow?.map((item) => item.id);
        setConfirmationModalVisibility({
          infoText: `Are you sure you want to passivate repricer for the selected listing/s?`,
          confirmText: "Yes, Passivate",
          callBack: (setSubmit) => {
            callEndpoint({
              title: `Passivating Listing Repricer`,
              url: `account-listings/repricer/passivate`,
              method: "POST",
              data: {
                account_listing_ids: ids,
              },
              pureData: true,
            }).then((res) => {
              setSubmit(false);
              if (res.type === "success") {
                setConfirmationModalVisibility(null);
                pageData?.filters?.filters?.has_repricer == null ? updateRows(ids, res) : deleteRows(ids, res?.data);
                setSelectedRow(null);
              }
            });
          },
        });
      },
    },
    activate: {
      label: "Upsert Repricer(s)",
      onClick: (row) => {
        row && setSingleData([row]);
        setRepricerModalVisibility(true);
        setModalTitleName("Multiple Listings");
      },
    },
    connect: {
      label: "Connect Product",
      onClick: (item) => {
        const items = item?.id ? [item] : selectedRow;
        const filteredItems = items.filter(
          (item) => item?.sales_channel_listing?.product == null
        );
        setConnectFormData(filteredItems);
        setModalVisibility(true);
      },
      condition: (item) => item?.sales_channel_listing?.product == null,
    },
    priceHistory: {
      label: "Price History",
      onClick: (row) => {
        openPriceHistoryModal(row);
      },
      bulkDisable: true,
    },
  };

  const updateRowWithTimeout = (id, path, value, delay) => {
    setTimeout(() => {
      dispatch(updateRow({ path, value }));
    }, delay);
  };

  const handleRowDeletion = (id, rowsRepricer, rows) => {
    dispatch(updateRow({
      path: `salesListings.[id:${id}].repricer`,
      value: rows.find(item => item.id === rowsRepricer)
    }));

    dispatch(updateRow({
      path: `salesListings.[id:${id}]._updatedRow`,
      value: true
    }));

    updateRowWithTimeout(id, `salesListings.[id:${id}]._deletedRow`, true, 1000);
    
    updateRowWithTimeout(id, `salesListings.[id:${id}]`, undefined, 2000);
  };


  const deleteRows = (ids, rows) => {
    dispatch(updateMeta({
      ...pageData.meta,
      to: pageData.meta.to - ids.length,
      total: pageData.meta.total - ids.length
    }));

    ids.forEach(id => {
      const rowsRepricer = pageData.salesListings.find(row => row.id === id)?.repricer?.id;
      handleRowDeletion(id, rowsRepricer, rows);
    });

    const storeQuery = storeQueryBuilder(pageData?.filters, {});
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
    dispatch(fetchFilters(urlQuery));
  };

  const updateRows = (ids, res) => {
    ids.map((item) => {
      const rowsRepricer = pageData.salesListings.find((row) => row.id === item)
        ?.repricer?.id;
      dispatch(
        updateRow({
          path: `salesListings.[id:${item}].repricer`,
          value: res.data.find((item) => item.id === rowsRepricer),
        })
      );
      dispatch(
        updateRow({
          path: `salesListings.[id:${item}]._updatedRow`,
          value: true,
        })
      );

      setTimeout(() => {
        dispatch(
          updateRow({
            path: `salesListings.[id:${item}]._updatedRow`,
            value: false,
          })
        );
      }, 3000);
    });
  };

  const statusList = {
    1: {
      label: "Pending",
      actions: [
        actionList.ruleSettings,
        actionList.passivate,
        actionList.activate,
        actionList.connect,
        actionList.priceHistory,
      ],
    },
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    return getActionList(row, statusList, undefined, undefined, true);
  };

  const handleRefreshListings = async () => {
    const identifiers = selectedRow?.map(
      (item) => item?.sales_channel_listing?.identifier
    );
    const ids = selectedRow?.map((item) => item?.id);

    if (identifiers?.length > 0) {
      try {
        setRefreshing(true);
        let notificationId = startNotification({
          title: `Refreshing ${identifiers.length} Listings`,
        });

        const response = await dispatch(
          refreshAccountListings({ identifiers })
        ).unwrap();
        setRefreshing(false);
        handleNotification(response, notificationId);
        if (response.type === "success") {
          updateListings(response, identifiers, ids);
        }
        setSelectedRow(null);
      } catch (error) {
        console.error("Error refreshing account listings:", error);
      }
    } else {
      util.notifier({ message: "Please Select at Least One Row" });
      console.error("No identifier found in the selected row.");
    }
  };

  const handleNotification = (response, notificationId) => {
    closeNotification(notificationId, {
      toast: {
        type: response.type === "validation_error" ? "error" : response.type,
        message: response.message,
        errors: response.errors,
      },
    });
  };

  const updateListings = (response, identifiers, ids) => {
    identifiers.forEach((identifier) => {
      const row = response.data.find((item) => item.identifier === identifier);
      const originalRow = pageData.salesListings.find(
        (item) => item.sales_channel_listing.identifier === identifier
      );
      dispatch(
        updateRow({
          path: `salesListings.[id:${originalRow.id}]`,
          value: {
            ...originalRow,
            sales_channel_listing: row,
            _updatedRow: true,
          },
        })
      );
    });

    ids.forEach((id) => {
      setTimeout(() => {
        dispatch(
          updateRow({
            path: `salesListings.[id:${id}]._updatedRow`,
            value: false,
          })
        );
      }, 3000);
    });
  };

  // Handle Tabs

  const onHandleTab = (item) => {
    setSelectedRow([]);
    switch (item) {
      case "0":
        onFilterApply({ has_repricer: item });
        break;
      case "1":
        onFilterApply({ has_repricer: item });
        break;
      default:
        onFilterApply({ has_repricer: null });
        break;
    }
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };
  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const handleTableFilters = (filters, bound) => {
    onFilterApply(filters, bound);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      changeSort = sort;
    } else {
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 20,
      page: page || currentStoreFilters.page || 1,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery), currentStoreFilters);
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };
  const fulfilmentHandler = async (item, id, index) => {
    await dispatch(
      updateSalesListingFulfilment({
        type: item,
        payload: Array.isArray(id) ? id : [id],
      })
    )
      .then(({ payload }) => {
        if (payload?.data?.type === "success") {
          if (Array.isArray(id)) {
            onPaginationApply();
            util.notifier(payload?.data);
          } else {
            dispatch(fetchSalesListing(id)).then((rs) => {
              if (visibleListingModal) {
                setSalesModalListingData(rs.payload.data);
              }
              dispatch(
                updateRow({
                  path: `salesListings.[id:${id}]`,
                  value: rs.payload.data,
                })
              );
              util.notifier(payload?.data);
            });
          }
        }
      })
      .catch((error) => {
        notifier({ type: "error", content: error });
      });
  };
  const handleCallBackActions = ({ action, data }) => {
    actionList[action].onClick(data);
  };

  const onSubmitApply = async (data, onClose) => {
    if (isDefaultClicked) {
      const temp = Object.fromEntries(
        Object.entries({ ...data }).filter(([key, value]) => value !== null)
      );
      callEndpoint({
        title: `Updating Default Settings`,
        url: `repricer/default-settings`,
        method: "PUT",
        data: temp,
        pureData: true,
      }).then((res) => {
        if (res.type !== "validation_error") {
          onClose();
          onPaginationApply();
        }
      });
    } else {
      const arr = selectedRow?.length ? selectedRow : singleData;

      const payload = arr
        ?.map((item) => {
          if (item === null || item === undefined) {
            return null; // or simply skip this item by filtering it out beforehand
          }
          let temp;
          if (data?.set_repricing_rule) {
            temp = Object.fromEntries(
              Object.entries({
                ...defaultSettings,
                account_listing_id: item.id,
              }).filter(([key, value]) => {
                console.log(value);
                return (
                  value !== null &&
                  key !== "created_at" &&
                  key !== "_id" &&
                  key !== "updated_at"
                );
              })
            );
          } else {
            temp = Object.fromEntries(
              Object.entries({ ...data, account_listing_id: item.id }).filter(
                ([key, value]) => value !== null
              )
            );
          }

          return temp;
        })
        .filter((item) => item !== null);

      callEndpoint({
        title: `Updating Listing Repricer`,
        url: `account-listings/repricer/upsert`,
        method: "POST",
        data: { listings: payload },
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          onClose();
          onPaginationApply();
        }
      });
    }

    setIsDefaultClicked(false);
  };

  const exportMapper = (data) => {
    const formatValue = (value, defaultValue = "-") => value || defaultValue;

    const mapItem = (item) => {
      const repricer = item?.repricer || {};
      const salesChannelListing = item?.sales_channel_listing || {};
      const product = salesChannelListing?.product || {};
      const brand = product?.brand || {};
      const account = item?.account || {};
      const channel = salesChannelListing?.channel || {};

      return {
        "Updated At": moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
        "Repricer Status": formatValue(repricer.status),
        "Buybox Owner": formatValue(salesChannelListing.buybox_owner_seller_id),
        Channel: formatValue(channel.name),
        Account: formatValue(account.name),
        Brand: formatValue(salesChannelListing.brand),
        Identifier: formatValue(salesChannelListing.identifier),
        "Product APID": formatValue(product.apid),
        "Product Assignee": formatValue(brand.assigned?.name),
        Title: formatValue(salesChannelListing.name),
        "BB Low Price": formatValue(salesChannelListing.bb_low_price),
        "Buybox Price": formatValue(salesChannelListing.buybox_price),
        "Buybox Seller Type": formatValue(
          salesChannelListing.buybox_seller_type
        ),
        Rating: formatValue(salesChannelListing.ratings),
        "Reviews Count": formatValue(salesChannelListing.reviews_count),
        "Variation Count": formatValue(salesChannelListing.variation_count),
        "Root Category": formatValue(salesChannelListing.root_category),
        "Sales Rank": formatValue(salesChannelListing.sales_rank),
        Image: formatValue(salesChannelListing.main_image_path),
        URL: formatValue(salesChannelListing.url),
        "FBA Rule Type": formatValue(repricer.fba_rule_type),
        "FBA Rule Value Type": formatValue(repricer.fba_rule_value_type),
        "FBA Rule Value": formatValue(repricer.fba_rule_value),
        "FBM Rule Type": formatValue(repricer.fbm_rule_type),
        "FBM Rule Value Type": formatValue(repricer.fbm_rule_value_type),
        "FBM Rule Value": formatValue(repricer.fbm_rule_value),
        "Price Type": formatValue(repricer.price_type),
        "FBA Price Min": formatValue(repricer.fba_price_min),
        "FBA Price Min USD": formatValue(repricer.fba_price_min_usd),
        "FBA Price Min Percentage": formatValue(
          repricer.fba_price_min_percentage
        ),
        "FBA Price Max": formatValue(repricer.fba_price_max),
        "FBA Price Max USD": formatValue(repricer.fba_price_max_usd),
        "FBA Price Max Percentage": formatValue(
          repricer.fba_price_max_percentage
        ),
        "FBM Price Min": formatValue(repricer.fbm_price_min),
        "FBM Price Min USD": formatValue(repricer.fbm_price_min_usd),
        "FBM Price Min Percentage": formatValue(
          repricer.fbm_price_min_percentage
        ),
        "FBM Price Max": formatValue(repricer.fbm_price_max),
        "FBM Price Max USD": formatValue(repricer.fbm_price_max_usd),
        "FBM Price Max Percentage": formatValue(
          repricer.fbm_price_max_percentage
        ),
        "AI Suggestion": formatValue(repricer.ai_suggestion),
      };
    };

    return data?.length ? data.map(mapItem) : [];
  };

  const headers = [
    "Updated At",
    "Repricer Status",
    "Buybox Owner",
    "Channel",
    "Account",
    "Brand",
    "Identifier",
    "Product APID",
    "Product Assignee",
    "Title",
    "BB Low Price",
    "Buybox Price",
    "Buybox Seller Type",
    "Rating",
    "Reviews Count",
    "Variation Count",
    "Root Category",
    "Sales Rank",
    "Image",
    "URL",
    "FBA Rule Type",
    "FBA Rule Value Type",
    "FBA Rule Value",
    "FBM Rule Type",
    "FBM Rule Value Type",
    "FBM Rule Value",
    "Price Type",
    "FBA Price Min",
    "FBA Price Min USD",
    "FBA Price Min Percentage",
    "FBA Price Max",
    "FBA Price Max USD",
    "FBA Price Max Percentage",
    "FBM Price Min",
    "FBM Price Min USD",
    "FBM Price Min Percentage",
    "FBM Price Max",
    "FBM Price Max USD",
    "FBM Price Max Percentage",
    "AI Suggestion",
  ];

  let activeFilter = pageData?.filters?.filters?.has_repricer != null ? `${pageData?.filters?.filters?.has_repricer}` : null;

  const filterCount =
    Object.values(pageData?.filters?.filters ?? {}).filter(
      (item) => item !== null && item !== "" && item !== undefined
    ).length -
    (pageData?.filters?.filters?.has_repricer === undefined ||
    pageData?.filters?.filters?.has_repricer === null
      ? 0
      : 1) -
    1;

  const calculateHeight = () => {
    if (product_id) return "calc(100vh - 430px)";

    if (window.innerWidth >= 1300)
      return `calc(100vh - ${filterCount > 0 ? 250 : 214}px)`;
    if (window.innerWidth >= 1000)
      return `calc(100vh - ${filterCount > 0 ? 250 : 270}px)`;
    if (window.innerWidth >= 768)
      return `calc(100vh - ${filterCount > 0 ? 300 : 270}px)`;
    return `calc(100vh - ${filterCount > 0 ? 370 : 234}px)`;
  };

  const contentHeight = calculateHeight();

  const [isDefaultClicked, setIsDefaultClicked] = useState(false);
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          displayTitle={displayTitle}
          title={"Automate Repricing"}
          loading={pageData?.loading}
          allowExport={true}
          exported={{
            exportData:
              selectedRow?.length > 0 ? selectedRow : pageData?.salesListings,
            exportMapper: exportMapper,
            headers: headers,
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.boundFilters
            ),
            discard: [
              "awaiting_connection",
              [hideFilter],
              "available",
              "has_repricer",
            ],
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count: filterCount,
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: () => {
                setRepricerModalVisibility(true);
                setFormData(defaultSettings);
                setIsDefaultClicked(true);
              },
              view: SettingsButton,
            },
            {
              callBack: handleRefreshListings,

              data: refreshing,
              view: RefreshButton,
              condition: () => selectedRow?.length > 0,
            },
          ]}
        />

        {/* Tabs and search input are here. */}
        {!showCreateForm && (
          <div
            className={
              "flex flex-row justify-between px-[16px] py-[4px]  h-[50px] items-center"
            }
          >
            <SearchInput
              className="w-[362px]"
              placeholder="Search..."
              onSubmit={onSearchApply}
              defaultValue={pageData?.filters?.search}
            />
            <div className="flex items-center gap-4">
              <TabButton
                callBack={onHandleTab}
                loading={pageData?.filterLoading}
                active={activeFilter}
                list={[
                  {
                    id: "0",
                    name: "No Repricing",
                    count: pageData?.filtersMeta?.repricer?.find(
                      (item) => item?.name === "inactive"
                    )?.count,
                  },
                  {
                    id: "1",
                    name: "Repricing",
                    count: pageData?.filtersMeta?.repricer?.find(
                      (item) => item?.name === "active"
                    )?.count,
                  },
                ]}
                allTab={true}
                allCount={pageData?.filtersMeta?.repricer?.reduce(
                  (acc, item) => acc + item.count,
                  0
                )}
              />
            </div>
          </div>
        )}

        {/* Table is here. */}
        <ArbitProductPage.Content className="content">
          <style>
            {`.content .contt { height: ${contentHeight} !important; }`}
          </style>
          <DynamicTable
            view={tableView}
            data={tableView(
              pageData?.salesListings?.map((item) => {
                return {
                  ...item,
                  status: 1,
                };
              }),
              fulfilmentHandler,
              openListingModal,
              handleCallBackActions,
              handleTableFilters,
              defaultSettings,
              actionList.connect,
              handleTableFilters
              // data
            )}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            loading={pageData.loading}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              header: {
                className:
                  "justify-center  !pl-[8px] !pr-[8px] !h-[40px] !text-[14px] font-semibold",
                span: "!text-[#4B5563]",
                "tracking id": { className: "!pr-[30px]" },
                listing: { className: "!pr-[88px] " },
                image: { className: "hidden" },
                notes: { className: "hidden" },
                "last update": { className: "hidden" },
                control: { className: "hidden" },
                type: { className: "!py-[0px] hidden" },
                _actions: { className: "!py-[0px] !px-[0px]" },
                channel: { className: " !flex !justify-center " },
                "LISTING ANALYSIS": {
                  className: "!text-start !flex ",
                },
                "Listing sales": {
                  className: "!text-start !flex ",
                },
              },
              row: {
                className:
                  "py-[0px] px-[16px] items-center justify-center flex text-[12px] !h-[104px] ",
                // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                description: {
                  className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                },
                listing: {
                  className:
                    "!justify-center !min-w-[506px] w-[100%] !px-[0px] !pl-[0px] !pr-[0px]",
                  td: "w-[100%]",
                },
                notes: { className: "!px[0px] !pr-[0px]" },
                "LISTING ANALYSIS": { className: " !px-[0px]" },
                channel: { className: "!px[0px]  " },
                "last update": {
                  className: "  !w-[120px] !flex !justify-start !p-2 ",
                },
                control: { className: "!px-[0px] !pr-[0px] !w-[60px]" },
                "wh eligibility": { className: "!px-[8px]" },
                _actions: {
                  className: "!py-[0px] !px-[0px] !gap-0 !min-w-[50px]",
                  innerClass: "!gap-0 ",
                  td: "!w-[50px]",
                },
                "Listing sales": {
                  className: "!py-[0px] !px-[12px] !pr-[0px]",
                },
                "FBA MIN / MAX PRICE": {
                  className: "!p-2",
                },
                "FBM MIN / MAX PRICE": {
                  className: "!p-2",
                },
              },
            }}
          />
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
          onPage={onHandlePage}
          resultsText={"Listings"}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {modalVisibility && (
        <ConnectForm
          data={connectFormData}
          show={modalVisibility || showSalesModal}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
            setShowSalesModal(false);
          }}
          defaultListings={connectFormData}
          callBack={() => {
            onPaginationApply();
            updateListingsCount("account-listings", "sales");
          }}
          updateRows={(items) => {
            setSelectedRow([]);
            items.map((item) => {
              dispatch(fetchSalesListing(item)).then((rs) => {
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]`,
                    value: rs.payload.data,
                  })
                );
                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]._updatedRow`,
                    value: true,
                  })
                );
              });
            });
          }}
          product_id={product_id}
          // onSubmit={onConnect}
        />
      )}
      {repricerModalVisibility && (
        <FormModal
          title={isDefaultClicked ? "Default Repricing Rule" : modalTitleName}
          formView={customRuleFormView}
          visibility={repricerModalVisibility}
          noPrefix={true}
          buttonsContainer={"mt-2"}
          data={formData}
          params={{
            params: isDefaultClicked,
            auto_kickoff: defaultSettings?.auto_kickoff,
          }}
          onClose={() => {
            setRepricerModalVisibility(false);
            setFormData(null);
            setModalTitleName("");
            setIsDefaultClicked(false);
          }}
          onSubmit={onSubmitApply}
          container={Container}
        />
      )}

      {visibleListingModal && (
        <ListingDetailModal
          show={visibleListingModal}
          onClose={() => setVisibleListingModal(false)}
          modalListing={salesModalListingData}
          fulfilmentHandler={fulfilmentHandler}
        />
      )}
      {visiblePriceHistoryModal && (
        <PriceHistoryModal
          onClose={() => setVisiblePriceHistoryModal(false)}
          data={salesModalListingData}
        />
      )}
      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={confirmationModalVisibility?.infoText}
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={confirmationModalVisibility?.confirmText}
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

const RefreshButton = ({ callBack, data }) => (
  <Button
    item={{
      label: (
        <div className={`flex flex-row items-center  justify-center gap-[4px]`}>
          <div className={"flip-x transform scale-x-[-1]"}>
            <Icons.ArrowPath
              className={`h-5 w-5 ${
                data ? "animate-spin rotate-[reverse]" : ""
              }`}
            />
          </div>
        </div>
      ),
      buttonType: "icon",
      className: "!w-auto !min-w-[28px] !h-[28px] !p-0 ",
    }}
    onClick={() => {
      if (!data) {
        callBack();
      }
    }}
  />
);

RefreshButton.propTypes = {
  callBack: PropTypes.func.isRequired,
  data: PropTypes.bool.isRequired,
};

const Container = (children) => (
  <div className="!w-[700px] grid grid-cols-12 items-start gap-[16px]  justify-center border-t border-t-gray-200  !px-[1px] pt-4">
    {children}
  </div>
);

const SettingsButton = ({ callBack }) => (
  <Button
    item={{
      label: (
        <div className={`flex flex-row items-center justify-center gap-[4px] `}>
          <div className={"flip-x transform scale-x-[-1]"}>
            <Icons.Cog className={"h-5 w-5"} />
          </div>
        </div>
      ),
      buttonType: "icon",
      className: "!min-w-[28px] !h-[28px] !p-0 ",
    }}
    onClick={() => {
      callBack();
    }}
  />
);
SettingsButton.propTypes = {
  callBack: PropTypes.func.isRequired,
};

RepricerListings.propTypes = {
  product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayTitle: PropTypes.string,
  displayTabGroups: PropTypes.string,
  showCreateForm: PropTypes.bool,
  showSalesModal: PropTypes.bool.isRequired, // Ensure this prop is passed
  hideFilter: PropTypes.bool,
  setShowSalesModal: PropTypes.func.isRequired,
  updateListingsCount: PropTypes.func.isRequired,
};

export default RepricerListings;

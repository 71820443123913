import React, { useEffect } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";
import style from "../../../dynamic-form/elements/select-util/style";
import PopupUp from "../../../dynamic-page/util/popUp";

const CarrerSelect = ({ item, submit, disabled }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);

  useEffect(() => {
    const fetchCarriers = async () => {
      if (
        (item?.warehouse?.id || item?.onWarehouse?.id) &&
        item?.billable_weight && item?.shipping_content
      ) {
        const fromStateId =
          item?.onWarehouse?.state?.id || item?.warehouse?.state?.id;
        setUpdate(true);
        let url =
          "api/v1/logistics/calculator" +
          `?from_state_id=${fromStateId}&to_state_id=${
            item?.state
          }&billable_weight=${item?.billable_weight}&fresh=${item?.fresh || 0}`;
        const response = await axios.get(url, {
          withCredentials: true,
          skipCancel: true,
        });
        const rs = response?.data;
        setCarriers(rs?.data);
        if (rs?.data && rs.data.length > 0) {
          const firstCarrier = {
            label: rs.data[0].carrier?.name + " - " + rs.data[0].service_name,
            value: rs.data[0].id,
            cost: rs.data[0].cost,
            code: rs.data[0].service_code,
            date: rs.data[0].updated_at,
            provider: rs.data[0].carrier?.provider?.name,
          };
          setSelectedItem(firstCarrier);
          submit({
            carrierId: firstCarrier.value,
            cost: firstCarrier.cost,
            code: firstCarrier.code,
            date: firstCarrier.date,
            provider: firstCarrier.provider,
          });
        }
        setUpdate(false);
      }
    };

    fetchCarriers();
  }, [
    item?.warehouse?.id,
    item?.fromStateId,
    item?.onWarehouse?.id,
    item?.orderId,
  ]);

  const handleSelectedItem = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
      submit({ carrierId: null, cost: null });
    } else {
      setSelectedItem(item);
      submit({ carrierId: item.value, cost: item.cost, code: item.code, date:item.date , provider: item.provider });
    }
  };

  const options = carriers.map((carrier) => ({
    label: carrier.carrier?.name + ' - ' + carrier.service_name,
    value: carrier.id,
    cost: carrier.cost,
    code: carrier.service_code,
    date: carrier.updated_at,
    provider: carrier.carrier?.provider?.name,
  }));

  let SelectComponent;
    if (!item?.billable_weight || !item?.shipping_content) {
      SelectComponent =  <PopupUp
        toggle={Toggle}
        action={'hover'}
        toggleClassName="cursor-default"
      >
        <div className="flex flex-col gap-1">
          <span>
            {item?.billable_weight <= 0 && "Billable weight is required"}
          </span>
          <span>
            {!item?.shipping_content && "Shipping content is required"}
          </span>
        </div>
      </PopupUp>;
    } else {
      SelectComponent =  (
        <Select
          options={options}
          value={selectedItem}
          onChange={handleSelectedItem}
          placeholder="Select Carrier"
          isDisabled={disabled}
          styles={style({
            style: {
              control: {
                height: "42px !important",
                minHeight: "auto",
                width: "200px !important",
                minWidth: "200px !important",
                background: "#F9FAFB",
              },
              menu: {
                whiteSpace: "wrap !important",
              },
              valueContainer: {
                width: "174px !important",
                minWidth: "174px !important",
              },
              singleValue: {
                width: "154px !important",
                fontSize: "14px !important",
                minWidth: "154px !important",
              },
            },
          })}
        />
      );
    }
  

  return (
    <>
      {update ? (
        <div className="flex justify-center items-center">
          <div className="w-10 h-10 border-t-transparent border-solid animate-spin rounded-full border-blue-500 border-8"></div>
        </div>
      ) : (
        SelectComponent
      )}
    </>
  );
};

const Toggle = () => (
          <Select
            placeholder="Select Carrier"
            isDisabled={true}
            styles={style({
              style: {
                control: {
                  height: "42px !important",
                  minHeight: "auto",
                  width: "200px !important",
                  minWidth: "200px !important",
                  background: "#F9FAFB",
                },
                menu: {
                  whiteSpace: "wrap !important",
                },
                valueContainer: {
                  width: "174px !important",
                  minWidth: "174px !important",
                },
                singleValue: {
                  width: "154px !important",
                  fontSize: "14px !important",
                  minWidth: "154px !important",
                },
                placeholder: {
                  textAlign: "start !important",
                },
              },
            })}
          />
        )

CarrerSelect.propTypes = {
  item: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CarrerSelect;

import constants from "../../../constant";
import axios from "../../../middleware/axios";
import { closeNotification, startNotification } from "./notifierLive";

import createFormDataFromJSON from "../../dynamic-form/util/convertJsonToFormData";

const callEndpoint = (api) => {
  let url = constants.APIURL.API_CONSTANT.concat(api.url);
  let a = null;
  if (api.title) {
    a = startNotification({
      title: api.title,
      message:
        api.message ||
        "Please do not close the window until the process is completed...",
    });
  }

  let requestData = null;

  if (api?.data) {
    requestData = api.pureData
      ? api.data
      : createFormDataFromJSON(api.data, api?.includeEmpty);
  }

  return new Promise((resolve, reject) => {
    axios({
      method: api.method || "GET",
      url: url,
      data: requestData,
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.data) {
          response.data = { type: "success" };
        }

        let toast;

        if (api?.toast) {
          toast = api.toast(response);
        } else if (api.onlyShowMessage) {
          toast = {
            message: response.data.message,
            type: response.data.type,
          };
        } else {
          toast = response.data;
        }
        const message =
          response?.status === 200 || response?.status === 204
            ? response?.data?.message || "Successful Operation"
            : response?.data?.message;

        if (a)
          closeNotification(a, {
            toast: {
              ...toast,
              title: api.title,
              message,
            },
          });
        resolve(response.data || response);
      })
      .catch((error) => {
        if (a)
          closeNotification(a, {
            toast: {
              type: "error",
              message: !error?.response?.data?.errors ? error?.response?.data?.message : "An error occurred",
              errors: error?.response?.data?.errors || {},
            },
          });
        reject(new Error(error));
      });
  });
};

export default callEndpoint;

import React, { useEffect } from "react";

import Icons from "./../../icons";
import api from "../../../../store/orders/api";

import priceHandler from "../../../../utils/priceHandler";
import upperCaseString from "../../../dynamic-page/util/upperCaseString";
import HandmadeSkeleton from "../../../../utils/skeleton";

import axios from "axios";
import shortenString from "../../../dynamic-page/util/shortenString";
import Button from "../../../dynamic-form/elements/Button";
import PopupUp from "../../../dynamic-page/util/popUp";
import PropTypes from "prop-types";
import notifier from "../../../dynamic-page/util/notifier";

const CreateShipmentView = ({ item, submit }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(true);
  const [awaitingTransfersCount, setAwaitingTransfersCount] = React.useState(0);
  const [warehouseInfo, setWarehouseInfo] = React.useState(null);

  const headList = ["provider", "CARRiER", "SERViCE", "T. COST"];

  const fetchWarehouseInfo = async () => {
    if (item?.onWarehouseInfo) {
      try {
        const response = await axios.get(
          `/api/v1/${
            item?.onWarehouseType === "pickup" ? "pickup-points" : "warehouses"
          }/${item?.onWarehouseInfo?.id}`
        ); // Assuming this endpoint exists
        setWarehouseInfo(response.data.data);
      } catch (error) {
        console.error("Failed to fetch warehouse info", error);
      }
    }
  };

  const tooltip = (cost) => {
    return [
      {
        label: "Carrier Shipment Cost",
        value: cost?.cost,
        condition: true,
      },
      {
        label:
          item?.onWarehouseType === "pickup"
            ? "Pick-up Point Transfer Cost"
            : "Warehouse Transfer Cost",
        value: +item?.billable_weight * (warehouseInfo?.transfer_cost || 0),
        condition:
          item?.onWarehouse != item?.warehouseId ||
          item?.onWarehouseType === "pickup",
      },
      {
        label: "Pick-up Point Processing Cost",
        value: +item?.items_qty * (warehouseInfo?.processing_fee || 0),
        condition:
          item?.onWarehouse != item?.warehouseId ||
          item?.onWarehouseType === "pickup",
      },
      {
        label: "Total Shipment Cost",
        value:
          +cost?.cost +
          (item?.onWarehouse != item?.warehouseId ||
          item?.onWarehouseType === "pickup"
            ? +item?.billable_weight * (warehouseInfo?.transfer_cost || 0) +
              +item?.items_qty * (warehouseInfo?.processing_fee || 0)
            : 0),
        condition: true,
      },
    ];
  };

  useEffect(() => {
    const fetchCarriers = () => {
      if (item?.orderId && item?.warehouseId && item?.fromStateId) {
        setUpdate(true);
        api.fetchShipmentCarrier(item).then((rs) => {
          setCarriers(rs?.data);
          setUpdate(false);
        }).catch((error) => {
          notifier(error?.response?.data);
          setUpdate(false);
        });
        setWarehouseInfo(item?.onWarehouseInfo);
        if (
          item?.onWarehouse !== item?.warehouseId &&
          item?.onWarehouseInfo?.state?.id
        ) {
          axios
            .get(
              `/api/v1/orders/filters?filter[shipment_label_state_id]=${item?.onWarehouseInfo?.state?.id}&filter[order_status]=15`
            )
            .then((res) => {
              setAwaitingTransfersCount(
                res?.data?.data?.statuses?.find((key) => key?.id === 15)?.count
              );
            });
        }
      }
    };

    fetchCarriers();
  }, [item?.onWarehouseInfo, item?.orderId]);

  const handleSelectedItem = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
      setDisableButton(true);
    } else {
      setSelectedItem(item);
      setDisableButton(false);
    }
  };

  const daysHoursAgoFromIso = (date) => {
    const now = new Date();
    const inputDate = new Date(date);

    const diff = now - inputDate;
    if (diff < 0) {
      return "few minutes";
    }

    const hours = Math.floor(diff / 1000 / 60 / 60) % 24;
    const days = Math.floor(diff / 1000 / 60 / 60 / 24);

    if (days === 0 && hours === 0) {
      return "few minutes";
    }

    return days > 0 ? `${days}d ${hours}h` : `${hours}h`;
  };

  return (
    <div
      className={
        "border-t border-t-gray-200 h-[inherit]  min-w-[700px]  flex flex-col"
      }
    >
      <div
        className={`${
          awaitingTransfersCount > 0
            ? "!max-h-[409px] min-h-[409px]"
            : "!max-h-[463px] min-h-[463px]"
        }  min-w-[924px] ${
          !update ? "overflow-y-auto" : "overflow-y-hidden"
        } flex flex-1 flex-col`}
      >
        <table className={`w-full table-auto h-max `}>
          <thead
            className={"sticky t-[0px] z-[2] l-[0px] bg-gray-50 w-full"}
            style={{ position: "sticky", top: 0, left: 0, zIndex: 2 }}
          >
            <tr className={"text-gray-600 text-[12px] font-semibold"}>
              {headList.map((key, index) => (
                <th
                  key={key}
                  className="justify-start !max-w-min w-fit text-left h-[42px] py-4 items-center px-[16px] border-b border-gray-200"
                >
                  <span className="whitespace-no-wrap block text-gray-500 uppercase text-[12px] leading-[18px] font-semibold select-none">
                    {key}
                  </span>
                </th>
              ))}
            </tr>
          </thead>

          <tbody className={"relative "}>
            {(() => {
              if (!update) {
                if (item?.orderId && item?.warehouseId && carriers?.length) {
                  return carriers.map((carrierItem, index) => (
                    <tr
                      className={`transition text-[14px] leading-[21px]  ${
                        carrierItem?.id === selectedItem
                          ? "bg-blue-100 hover:bg-blue-100 cursor-default border-b border-b-blue-200"
                          : "hover:bg-gray-50 bg-white cursor-pointer border-b border-b-gray-200"
                      }`}
                      key={carrierItem?.id}
                      onClick={() => handleSelectedItem(carrierItem?.id)}
                      style={{ height: "54px", boxSizing: "border-box" }}
                    >
                      {/* Carrier Name and Logo */}
                      <td
                        className={
                          "flex items-center max-h-[54px] w-[200px] flex-row gap-[8px] border-b-gray-200"
                        }
                        style={{
                          height: "54px",
                          padding: "0",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className={`p-[16px] flex flex-row gap-[4px] items-center font-medium leading-[21px]`}
                        >
                          <img
                            alt="carrier-logo"
                            src={carrierItem?.carrier?.provider?.logo}
                            className={"bg-none h-[16px] w-4"}
                          />
                          {upperCaseString(
                            carrierItem?.carrier?.provider?.name,
                            "first"
                          )}
                        </div>
                      </td>

                      {/* International Carrier */}
                      <td
                        className={"max-h-[54px] w-[200px] border-b-gray-200"}
                        style={{
                          height: "54px",
                          padding: "0",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className={`p-[16px] flex flex-row gap-[4px] items-center font-medium`}
                        >
                          {carrierItem?.carrier?.international_carrier?.logo ? (
                            <img
                              alt="carrier-logo"
                              src={
                                carrierItem?.carrier?.international_carrier
                                  ?.logo
                              }
                              className={"bg-none h-[16px] w-4"}
                            />
                          ) : (
                            <Icons.Track
                              className={"w-[16px] h-4 text-gray-300"}
                            />
                          )}
                          {shortenString(carrierItem?.carrier?.name, 19)}
                        </div>
                      </td>

                      {/* Service Name */}
                      <td
                        className={"max-h-[54px] border-b-gray-200"}
                        style={{
                          height: "54px",
                          padding: "0",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className={`p-[16px] flex flex-row gap-[8px] items-center font-medium min-w-[404px]`}
                        >
                          {upperCaseString(carrierItem?.service_name || "")}
                        </div>
                      </td>

                      {/* Total Shipment Cost */}
                      <td
                        className={"max-h-[54px] w-[120px] border-b-gray-200"}
                        style={{
                          height: "54px",
                          padding: "0",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          className={`p-[16px] flex flex-row gap-[8px] justify-between items-center font-semibold`}
                        >
                          $
                          {priceHandler(
                            tooltip(carrierItem)?.find(
                              (key) => key.label === "Total Shipment Cost"
                            )?.value
                          )}
                          <PopupUp
                            toggle={(selected) => (
                              <Icons.Calculator
                                className={`text-gray-300 h-5 w-5 hover:text-gray-500`}
                              />
                            )}
                            rightSpace={20}
                            portal={true}
                            action={"hover"}
                          >
                            <div className={"p-1 flex gap-[6px] flex-col"}>
                              {tooltip(carrierItem).map((key, index) => {
                                if (!key.value || !key.condition) return null;
                                return (
                                  <div
                                    className={
                                      "flex justify-between gap-[16px] min-w-[190px !font-normal"
                                    }
                                    key={key.value}
                                  >
                                    <span
                                      className={`text-gray-900 text-[12px] leading-5 ${
                                        key.label === "Total Shipment Cost"
                                          ? "!font-medium"
                                          : ""
                                      }`}
                                    >
                                      {key.label}
                                    </span>
                                    <span
                                      className={`text-gray-900 text-[12px] leading-5 ${
                                        key.label === "Total Shipment Cost"
                                          ? "!font-medium"
                                          : ""
                                      }`}
                                      style={{ direction: "ltr" }}
                                    >
                                      ${priceHandler(key.value)}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </PopupUp>
                        </div>
                      </td>
                    </tr>
                  ));
                } else {
                  return (
                    <div
                      className={
                        "w-[100%] min-h-[712px] px-6 py-3 gap-2 flex flex-col absolute"
                      }
                    >
                      <div
                        className={`flex flex-col h-full text-center py-[50px] rounded-2xl items-center `}
                      >
                        <Icons.BrokenHeart className="w-10 h-10 mb-4 text-gray-400" />
                        <p className={"w-[700px] text-gray-400 font-medium"}>
                          Sorry, we were unable to calculate shipping costs for
                          this region. Please check the order shipping details.
                          Or refreshing the data can often fix this issue.
                        </p>
                      </div>
                    </div>
                  );
                }
              } else {
                return (
                  <div
                    className={
                      "min-w-[924px] px-6 py-3 gap-2 flex flex-col absolute"
                    }
                  >
                    {[...Array(70)].map((_, index) => (
                      <HandmadeSkeleton key={_} className={"w-full h-9"} />
                    ))}
                  </div>
                );
              }
            })()}
          </tbody>
          {awaitingTransfersCount > 0 ? (
            <div className="h-[53px] absolute w-full bottom-[65px] p-4 bg-[#e5edff] rounded-md justify-start items-center gap-3 inline-flex">
              <Icons.Inbox />
              <div className="h-[21px] w-full justify-between items-center flex">
                <div>
                  <span className="text-[#42389d] text-sm font-normal font-['Inter'] leading-[21px]">
                    There are currently{" "}
                  </span>
                  <span className="text-[#42389d] text-sm font-medium font-['Inter'] leading-[21px]">
                    {awaitingTransfersCount} active shipments
                  </span>
                  <span className="text-[#42389d] text-sm font-normal font-['Inter'] leading-[21px]">
                    {" "}
                    awaiting transfer to your selected pick-up point.
                  </span>
                </div>
                <button
                  className="text-[#3e3876] text-sm font-semibold font-['Inter'] cursor-pointer leading-[21px]"
                  onClick={() => {
                    window.open(
                      "/logistics/orders?filter[order_status]=15",
                      "_blank"
                    );
                  }}
                >
                  View All
                </button>
              </div>
            </div>
          ) : null}
        </table>

        {!item?.warehouseId && (
          <div
            className={
              "text-gray-600 text-[12px] font-semibold w-full items-center justify-center flex flex-col my-[32px]"
            }
          >
            <span className={"text-[27px] text-gray-300 font-light"}>
              Select Warehouse!
            </span>
            <span className={"text-[14px] text-gray-300"}>
              Please select a warehouse before proceeding with the shipment
              creation.
            </span>
          </div>
        )}
      </div>
      <div
        className={
          "flex flex-row gap-[8px] justify-end  !w-full px-4 pt-3 absolute bottom-3 pb-0 h-[52px] "
        }
      >
        <div
          className={
            "flex w-full text-[16px]  items-center text-gray-600 gap-[6px]"
          }
        >
          {
            //listOfLastUpdate[0]?.price_last_update && carriers?.status !== "pending" &&
            <>
              <Button
                item={{
                  title: "Refresh",
                  popUp: {
                    leftSpace: 20,
                  },
                  className:
                    "h-[20px] w-[20px] bg-white text-gray-500 px-0 !justify-start",
                }}
                onClick={() => {
                  setUpdate(true);
                  fetchWarehouseInfo();
                  const fetchCarriers = () => {
                    if (item?.orderId && item?.warehouseId) {
                      const payload = {
                        ...item,
                        fresh: 1,
                      };
                      api.fetchShipmentCarrier(payload).then((rs) => {
                        setCarriers(rs?.data);
                        setUpdate(false);
                      });
                    }
                  };
                  fetchCarriers();
                }}
              >
                <Icons.Refresh
                  className={`w-[20px] h-[20px]  text-gray-500 ${
                    update ? "animate-spin" : ""
                  }`}
                />
              </Button>
              The costs were last updated{" "}
              <span className="text-gray-700 font-medium">
                {carriers?.length && !update ? (
                  <>{daysHoursAgoFromIso(carriers[0]?.updated_at)} ago.</>
                ) : null}
              </span>
            </>
          }
        </div>
        <Button
          item={{
            buttonType: "primary",
            className: "h-[40px] !w-[152px] !px-5 !py-2.5 whitespace-nowrap",
            disabled: disableButton, //selectedItem === null
          }}
          onClick={() => {
            if (!disableButton) {
              setDisableButton(true);
              submit({
                warehouse_id: item?.warehouseId,
                order_id: item?.orderId,
                shipment_pricing_id: selectedItem,
                callBack: () => {
                  setDisableButton(false);
                },
              });
            }
          }}
        >
          Create Shipment
        </Button>
      </div>
    </div>
  );
};
CreateShipmentView.propTypes = {
  item: PropTypes.object.isRequired, // Adjust the type based on your structure
  submit: PropTypes.func.isRequired,
};

export default CreateShipmentView;

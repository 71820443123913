import * as Yup from "yup";
import constants from "../../../constant";

const productComponentFormView = (formDefaultValue, params) => {
  return [
    {
      name: "product_id",
      label: "Products",
      placeholder: "Filter by Product",
      type: "Select",
      style: {
        control: {
          height: "40px !important",
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important ",
          zIndex: 9999,
        },
        option: {
          padding: "4px 8px !important",
        },
      },
      features: {
        components: {
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        },
        formatOptionLabel: (child) => {
          return (
            <div className={"flex flex-row  w-[500px] items-center gap-[8px]"}>
              {child?.data?.image ? (
                <img
                  className={"w-[32px] h-[32px] rounded-full"}
                  src={child?.data?.image}
                  alt="prodcut"
                />
              ) : (
                <img
                  className={"w-[32px] h-[32px] rounded-full"}
                  src={"/assets/images/noImage.png"}
                  alt="none"
                />
              )}
              <span className={"text-gray-800"}>{child?.label}</span>
            </div>
          );
        },
      },
      execludedValues: () => params.map((item) => item.id),
      api: {
        url: constants.APIURL.GET_PRODUCT + "?filter[type]=1&page=1&",
        query: "term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.title,
        }),
      },
      validation: Yup.string().required("Product is required"),
      className: "col-span-1 !max-w-[363.5px]",
    },
    {
      name: "quantity",
      label: "Quantity",
      placeholder: "Enter Quantity",
      type: "Number",
      defaultValue: formDefaultValue?.quantity || "",
      validation: Yup.string().required("Quantity is required"),
      className: "col-span-1 ",
    },
  ];
};
export default productComponentFormView;

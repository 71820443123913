import React, { useState } from "react";
import { HiRefresh } from "react-icons/hi";
import axios from "axios";
import PropTypes from "prop-types";

const daysHoursAgoFromIso = (date) => {
  const now = new Date();
  const inputDate = new Date(date);

  const diff = now - inputDate;
  if (diff < 0) {
    return "minutes";
  }

  const hours = Math.floor(diff / 1000 / 60 / 60) % 24;
  const days = Math.floor(diff / 1000 / 60 / 60 / 24);

  if (days === 0 && hours === 0) {
    return "minutes";
  }

  return days > 0 ? `${days}d ${hours}h` : `${hours}h`;
};

const CarrierCost = ({ item, states, onUpdateCarrier }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  console.log(states)
  const handleRefresh = async () => {
    if (isRefreshing || (!states?.onWarehouse?.state?.id && !states?.warehouse?.state?.id) || !item?.ship_to_state?.id) return;

    setIsRefreshing(true);
    try {

      const url = `api/v1/logistics/calculator?from_state_id=${states?.onWarehouse?.state?.id || states?.warehouse?.state?.id}&to_state_id=${item?.ship_to_state?.id}&billable_weight=${item?.billable_weight}&fresh=1`;

      const response = await axios.get(url, {
        withCredentials: true,
        skipCancel: true,
      });

      const carriers = response?.data?.data;
        if (carriers && carriers.length > 0) {
          console.log(states)
        // Find the carrier with matching service_code in the new data
        const selectedCarrier = carriers.find(
          (carrier) => carrier.service_code === item.code
        );
          console.log(selectedCarrier)
          console.log(selectedCarrier.updated_at)
        if (selectedCarrier) {
          onUpdateCarrier(item.id, {
            carrierId: selectedCarrier.id, // This will be the new ID
            cost: selectedCarrier.cost,
            code: selectedCarrier.service_code,
            date: selectedCarrier.updated_at,
          });
        }
      }
    } catch (error) {
      console.error("Error refreshing carrier cost:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  if (isRefreshing) {
    return (
      <div className="bg-gray-100 flex w-[112px] h-full items-center gap-2 p-2">
        <HiRefresh className="h-4 w-4 animate-spin text-blue-500" />
        <span className="text-gray-500">Getting...</span>
      </div>
    );
  }

  return (
      <div className="flex flex-col p-2 gap-[4px] relative  w-[112px]">
      <div className="text-[16px] font-medium text-gray-900 !leading-[24px] flex items-center gap-2">
        {item?.selectedCarrierCost ? `$${item.selectedCarrierCost}` : "-"}
      </div>
      {item.selectedCarrierId && (
        <button
          type="button"
          className="text-gray-400 cursor-pointer flex gap-1 items-center text-xs font-medium hover:text-gray-600 min-w-[16px]"
          onClick={handleRefresh}
        >
          <HiRefresh className="h-4 w-4" />
          {daysHoursAgoFromIso(item?.date)} ago
        </button>
      )}
    </div>
  );
};
CarrierCost.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selectedCarrierId: PropTypes.string,
    date: PropTypes.string,
    selectedCarrierCost: PropTypes.number,
    code: PropTypes.string.isRequired,
    billable_weight: PropTypes.number.isRequired,
    ship_to_state: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  states: PropTypes.shape({
    warehouse: PropTypes.shape({
      id: PropTypes.string.isRequired,
      state: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    onWarehouse: PropTypes.shape({
      id: PropTypes.string.isRequired,
      state: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    fromStateId: PropTypes.string.isRequired,
  }),
  onUpdateCarrier: PropTypes.func.isRequired,
};
export default CarrierCost;

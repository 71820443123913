import { HiRefresh } from "react-icons/hi";
import ImageMagnifier from "../../../../dynamic-page/debt/magnifier";
import Item from "../../../components/table/item";
import NumberPriorityColor from "../../../components/table/numberPriorityColor";
import OrderId from "../../../components/table/orderid";
import OrderRowAction from "../../../components/table/orderRowAction";
import ShipmentLabelView from "../../../components/table/shipmentLabelView";
import ShipTo from "../../../components/table/shipTo";
import Weight from "../../../components/table/weight";
import Icons from "../../../icons";
import CarrierCost from "../carrierCost";
import CarrerSelect from "../carrierSelect";
import { BsExclamationOctagonFill } from "react-icons/bs";

const CheckIcon = () => (
  <svg
    className="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#057A55"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
      clipRule="evenodd"
    />
  </svg>
);


const tableView = (list, states, handleDelete) => {
  console.log(list);
  return {
    list: list,
    theme: {
      delete: (item) => (
        <div className="text-gray-500 w-[32px] flex justify-center text-sm font-normal font-['Inter'] leading-[21px]">
          <Icons.TrashBin
            className={
              "w-[18px] h-[18px] text-red-400 cursor-pointer hover:text-red-700"
            }
            onClick={() => {
              handleDelete(item.id);
            }}
          />
        </div>
      ),
      control: (item) => (
        <div className={"w-[20px] h-[50px]"}>
          <OrderRowAction
            actions={{
              order_id: item?.id,
              customer_note: item?.customer_note,
              internal_notes: item?.notes_count,
              shipping_content: item?.shipping_content,

              customer_note_enabled: item?.customer_note_enabled,
              internal_notes_enabled: item?.internal_notes_enabled,
              shipping_content_enabled: item?.shipping_content_enabled,
            }}
            portal={true}
            view={true}
          />
        </div>
      ),
      status: (item) => (
        <div className={"flex flex-col gap-[8px] relative w-full"}>
          {item._loading && (
            <div className="flex justify-start items-center w-full gap-2">
              <HiRefresh className="animate-spin h-5 w-5" />
              <span className="text-sm font-medium font-['Inter'] leading-[21px]">
                Creating Shipment...
              </span>
            </div>
          )}
          {item._error && (
            <div className="flex justify-start items-center w-full gap-2">
              <BsExclamationOctagonFill className="text-red-700 h-5 w-5 " />
              <span className="text-sm font-medium font-['Inter'] leading-[21px]">Error while creating shipment</span>
            </div>
          )}
          {item._success && (
            <div className="flex justify-start items-center w-full gap-2">
              <ShipmentLabelView shipmentData={item?.shipment_labels} />
            </div>
          )}
          {!item._loading && !item._error && !item._success && (
            <div className="flex flex-col gap-1 w-full items-start">
              {!item?.shipping_content && (
                <div className="flex flex-row gap-1 items-center">
                  <BsExclamationOctagonFill className="text-yellow-600 h-5 w-5 " />
                  <span className="text-sm font-normal font-['Inter'] leading-[21px]">
                    Missing content
                  </span>
                </div>
              )}
              {item?.billable_weight <= 0 && (
                <div className="flex flex-row gap-1 items-center">
                  <BsExclamationOctagonFill className="text-yellow-600 h-5 w-5 " />
                  <span className="text-sm font-normal font-['Inter'] leading-[21px]">
                    Missing weight
                  </span>
                </div>
              )}
              {item?.shipping_content && item?.billable_weight > 0 && (
                <div className="flex flex-row gap-1 items-center">
                  <CheckIcon className="text-green-500" />
                  <span className="text-sm font-normal font-['Inter'] leading-[21px]">
                    Ready to shipment
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      ),

      // image: (item) => (

      // ),

      item: (item) => {
        let imageSrc;
        let isImageMagnifier = false;

        if (item?.items_count === 1) {
          if (item?.first_item?.image_url) {
            imageSrc = item.first_item.image_url;
            isImageMagnifier = true;
          } else {
            imageSrc = "/assets/images/noImage.png";
          }
        } else if (!item?.first_item) {
          imageSrc = "/assets/images/noImage.png";
        } else {
          imageSrc = "/assets/images/multiproduct.png";
        }
        return (
          <div
            className={"w-[100%]  flex flex-row   gap-1 justify-start"}
            style={{ width: "100%" }}
          >
            <div
              className={"w-[56px] h-[64px] justify-center items-center flex"}
            >
              {isImageMagnifier ? (
                <ImageMagnifier src={imageSrc} className={"!max-h-[56px] h-[56px] min-h-[56px] w-[56px] min-w-[56px]"} />
              ) : (
                <img alt="s" src={imageSrc} className={"h-[56px] w-[56px]"} />
              )}
            </div>
            <Item
              product={item?.order_items?.filter(
                (item) => +item?.unit_price >= 0
              )}
              isModal={true}
              // callBack={handleRemoveItemFromList}
              orderId={item?.id}
              order={item}
              // onFilterApply={onFilterApply}
            />
          </div>
        );
      },

      qty: (item) => {
        let color;

        if (item?.items_total_qty <= 1) {
          color = "gray";
        } else if (item?.items_total_qty <= 5) {
          color = "yellow";
        } else {
          color = "red";
        }
        return (
          <div className={"flex"}>
            <NumberPriorityColor
              number={item?.items_total_qty}
              color={color}
              className={"text-[14px] leading-[21px]"}
            />
          </div>
        );
      },
      weight: (item) => {
        const orderStatusId = item?.order_status_id || null;
        const billableWeight = item?.billable_weight
          ? (+item.billable_weight).toFixed(2)
          : "-";
        const actualWeight = item?.actual_weight
          ? (+item.actual_weight).toFixed(2)
          : "-";
        const dimWeight = item?.dim_weight
          ? (+item.dim_weight).toFixed(2)
          : "-";
        const orderId = item?.id || null;

        // selected ağırlık türünü belirlemek için bağımsız bir ifade
        let selectedWeight;
        if (+actualWeight > +dimWeight) {
          selectedWeight = "actual";
        } else if (+actualWeight < +dimWeight) {
          selectedWeight = "dimensional";
        } else {
          selectedWeight = "actual";
        }
        return (
          <div className={"flex flex-col gap-[0px]"}>
            <Weight
              view={true}
              order_status_id={orderStatusId}
              weight={{
                billable: billableWeight,
                actual: actualWeight,
                dim: dimWeight,
                selected: selectedWeight,
                orderId: orderId,
              }}
            />
          </div>
        );
      },
      "store & order id": (item) => (
        <div
          className={"flex flex-col gap-[0px] relative justify-start  w-full"}
        >
          <OrderId
            // callBack={callBack}
            orderId={item.id}
            order_numbers={item.order_numbers}
            order={{
              order_number: item?.order_numbers[0],
              marketplace: item?.sales_channel_account?.channel?.marketplace,
              ...item?.sales_channel_account,
            }}
            item={item}
            // onFilterApply={onFilterApply}
          />
        </div>
      ),
      "ship to": (item) => (
        <div className={"flex flex-col gap-[8px] relative !min-w-[141px]"}>
          <ShipTo
            order={item}
            state={item?.ship_to_state}
            name={item?.ship_to_name}
            city={item?.ship_to_city}
            country={item?.ship_to_country}
            customerOrderCount={item?.customer_order_count}
            isModal={true}
            order_address_id={item?.order_address_id}
          />
        </div>
      ),

      cost: (item) => (
        <CarrierCost
          item={item}
          states={states}
          onUpdateCarrier={states.onUpdateCarrier}
        />
      ),

      carrier: (item) => (
        <div className={"flex flex-col gap-[8px] relative  !min-w-[240x]"}>
          <CarrerSelect
            key={`${item?.id}`}
            item={{
              ...states,
              state: item?.ship_to_state?.id,
              billable_weight: item?.billable_weight,
              shipping_content: item?.shipping_content,
              orderId: item?.id,
              selectedCarrierId: item?.selectedCarrierId,
              selectedCarrierCost: item?.selectedCarrierCost,
            }}
            submit={({ carrierId, cost,code,date, fresh, refresh,provider }) => {
              if (states.onUpdateCarrier) {
                console.log("Carrier submit:", {
                  carrierId,
                  cost,
                  code,
                  date,
                  fresh,
                  refresh,
                });
                states.onUpdateCarrier(item.id, {
                  carrierId: carrierId ?? item?.selectedCarrierId,
                  cost: cost ?? item?.selectedCarrierCost,
                  code: code ?? item?.code,
                  date: date ?? item?.date,
                  provider: provider
                });
              }
            }}
            disabled={item?._loading || item?._success || (!states?.warehouse && !states?.onWarehouse)}
          />
        </div>
      ),
    },
  };
};
export default tableView;


import React from "react";
import {useDispatch} from "react-redux";
import {apiActions, updateRow} from "../../../store/returns";
import DynamicForm from "../../dynamic-form";
import PropTypes from "prop-types";
import util from "../../dynamic-page/util";


const ShippingContent = ({ shipping_content, gtip, id, identifier, view }) => {
  const [innerData, setInnerData] = React.useState({});
  const [isUpdating, setIsUpdating] = React.useState(false);

  

  let formItems = [
    {
      name: "shippingContent",
      label: "Shipping Content",
      placeholder: "Enter Shipping Content",
      type: "Text",
      autoFocus: !view,
      view: view,
      innerClassName: "max-w-[270px] overflow-x-auto",
      defaultValue: shipping_content,
      clear: false,
      reset: false,
      className: "w-full gap-[0px]",
      disabled: isUpdating,
    },
  ];

  const ref = React.useRef(null);

  const dispatch = useDispatch();
  const handleSaveContent = async (text) => {
    const { shippingContent } = text;
    setIsUpdating(true);

    if (shippingContent !== innerData.shippingContent) {
      dispatch(
        apiActions({
          method: "POST",
          data: { shipping_content: shippingContent || null },
          orderId: id,
        })
      ).then((rs) => {
        setIsUpdating(false);
        util.notifier(rs?.payload?.data || rs?.payload);
        if (rs?.payload?.data?.type === "success") {
            setInnerData({ ...innerData, shippingContent: shippingContent });
            console.log("innerData", innerData)
            dispatch(
              updateRow({
                path: `returns.[identifier:${identifier}].order.shipping_content`,
                value: shippingContent,
              })
            );
            dispatch(
              updateRow({
                path: `returns.[identifier:${identifier}].order.shipping_content_enabled`,
                value: shippingContent,
              })
            );
        }
      });
    }

    return true;
  };

  const lineAnimationStyles = {
    width: "50%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute",
    left: "0",
    animation: "line-animation 0.5s linear infinite",
  };
  return (
    <>
      {isUpdating && (
        <div
          style={{
            top: "15px",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "5px",
            backgroundColor: "white",
            position: "absolute",
            overflow: "hidden",
            borderRadius: "5px",
            background: "#jjj",
          }}
        >
          <div style={lineAnimationStyles}></div>
        </div>
      )}
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <div className={"flex-row gap-[8px] flex items-start justify-start"}>
        <div
          className={
            " flex flex-col items-center justify-start w-[300px] text-[14px]"
          }
        >
          <DynamicForm
            items={formItems}
            ref={ref}
            style={{
              label: {
                className:
                  "w-full border-t border-gray-200 pt-[12px] !text-gray-400 text-14px",
              },
              container: { className: "gap-[0px]" },
            }}
            onChange={handleSaveContent}
          />
        </div>
      </div>
    </>
  );
};
ShippingContent.propTypes = {
  shipping_content: PropTypes.string,
  gtip: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  identifier: PropTypes.string,
  view: PropTypes.bool,
};

export default ShippingContent;
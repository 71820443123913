export default (value, optionsList, innerValue, isMulti) => {
    
    console.log(value, optionsList, innerValue, isMulti);
    if (typeof value === "string" || typeof value === "number") {
        const returnValue = optionsList?.find(option => String(option?.value) === String(value));
        return returnValue ?? innerValue
    } else if (Array.isArray(value)) {
        if (isMulti) {
                return value.map(val => {
                    const returnValue = optionsList?.find(option => option?.value == val) || innerValue?.find(option => option?.value == val);
                    return returnValue;

                });
        } else {
            const returnValue = optionsList?.filter(option => value?.includes(option?.value));
        return returnValue
        }
    } else if (String(value) !== "0") {
        if (value === "") return null;
        if (value === null) return optionsList?.find(option => option?.value === null) || null;
    } else {
        return null;
    }

};
const sortView = ({callback}) => {
    return {
        list: [

            {
                label: "Created At",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                }
            },
            {
                label: "Type",
                value: "type",
                onClick: (item) => {
                    callback("type");
                }
            },
            {
                label: "Expiry Date",
                value: "expiry_date",
                onClick: (item) => {
                    callback("expiry_date");
                }
            },
            {
                label: "Quantity",
                value: "quantity",
                onClick: (item) => {
                    callback("quantity");
                }
            },
            {
                label: "Created By",
                value: "created_by",
                onClick: (item) => {
                    callback("created_by");
                }
            },
            {
                label: "Last Update",
                value: "updated_at",
                onClick: (item) => {
                    callback("updated_at");
                }
            },

        ]
    }
}

export default sortView;
import { createShipment } from "../../../../store/orders";
import { useDispatch } from "react-redux";
import notifier from "../../../dynamic-page/util/notifier";

const useCreateShipmentInitializer = () => {
  const dispatch = useDispatch();

  const initializeShipment = async (carrier, callBack) => {
    return new Promise((resolve) => {
      dispatch(createShipment(carrier)).then((rs) => {
        notifier(rs?.payload?.data || rs?.payload);
        callBack(rs?.payload?.data);
        if (carrier.callBack) {
          carrier.callBack();
        }
        resolve(rs?.payload?.data);
      });
    });
  };

  return { initializeShipment };
};

export default useCreateShipmentInitializer;

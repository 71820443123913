import Title from "./elements/Title";
import TitleNew from "./elements/TitleNew";
import classNames from "./elements/classNames";
import Tab from "./elements/Tab";
import Content from "./elements/Content";
import Footer from "./elements/Footer";
import PropTypes from 'prop-types';

const Page = ({ children, className }) => {
  const location = window.location.pathname;

  const isProduct = RegExp(/\/product\/\d+\/?/).exec(location)

  return <>
    <style>
      {`
        @media (max-width: 1024px) {
          .height-full {
            height: calc(100vh - ${isProduct ? '455' : '150'}px);
          }
        }
        @media (min-width: 1025px) {
          .height-full {
            height: calc(100vh - ${isProduct ? '392' : '120'}px);
          }
        }
      `}
    </style>
    <div className={classNames("font-inter height-full", className)}>{children}</div>
  </>;
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default {
  Page,
  Title,
  Content,
  Footer,
  Tab,
  TitleNew,
};

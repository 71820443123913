import upperCase from "../../dynamic-page/util/upperCaseString";
import * as Yup from "yup";
import icons from "../icons";
import constant from "../../../constant";
const MapPin = icons.mapPin;

const manualForm = (formDefaultValue) => {
  return [
    {
      name: "international_carrier_id",
      label: "Associated International Identifier",
      placeholder: "ex. UPS",
      api: {
        url: "/api/v1/international-carriers/index",
        query: "?term={query}",
        ignoreView: true,
        // view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      type: "Select",
      defaultBound: formDefaultValue?.international_carrier?.id
        ? {
            id: formDefaultValue?.international_carrier?.id,
            name: formDefaultValue?.international_carrier?.name,
          }
        : null,
      defaultValue: formDefaultValue?.international_carrier_id || null,
      validation: Yup.string().required("International Carrier is required"),
      className: "col-span-6",
      innerClassName: "bg-gray-50",
    },

    {
      name: "country_id",
      label: "Country",
      placeholder: "Select the country",
      type: "Select",
      reset: false,
      clear: true,
      api: {
        url: constant.APIURL.GET_COUNTRIES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
          flag: item.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={flag?.toLowerCase()} alt='country' />
            </span>
            <span>{upperCase(label)}</span>
          </div>
        ),
      },
      defaultValue: formDefaultValue?.country_id || null,
      // validation: Yup.string().required("Country is required"),
      className: "col-span-3",
      innerClassName: "bg-gray-50",
    },
    {
      name: "from_state_id",
      label: "State",
      placeholder: "Select the state",
      type: "Select",
      reset: false,
      clear: true,
      features: {
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: "/api/v1/state?filter[country_id]={country_id}",
        query: "&term={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: formDefaultValue?.from_state_id || null,
      execludedValues: (data) => data?.state_ids?.map((item) => item.id),
      className: "col-span-3",
      innerClassName: " bg-gray-50",
      // validation: Yup.string().required("State is required"),
    },
    {
      name: "tracking_number",
      label: "Tracking Number",
      type: "input",
      reset: false,
      clear: false,
      placeholder: "ex. 184701857085720",
      defaultValue: formDefaultValue?.tracking_number || null,
      className: "col-span-3",
      icon: <MapPin className="text-gray-500" />,
      innerClassName: "border border-gray-300 bg-gray-50",
      validation: Yup.string().required("Street 1 is required"),
    },
    {
      name: "billed_cost",
      label: "Billed Cost",
      type: "number",
      reset: false,
      clear: false,
      placeholder: "ex. 7 USD",
      defaultValue: formDefaultValue?.billed_cost || null,
      className: "col-span-3",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
    },
    {
      name: "label_file",
      label: "Label",
      type: "File",
      reset: false,
      clear: false,
      // placeholder: "ex. 184701857085720",
      defaultValue: formDefaultValue?.label_file || null,
      className: "col-span-6",
      innerClassName: "border border-gray-300 bg-gray-50",
      optional: true,
    },
  ];
};



export default manualForm;

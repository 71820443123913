/**
 * @author Austin Ames
 * @version 0.1.0
 * @description Title - a title of arbit product page
 */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import UI from "../../../dynamic-form/elements";
import {
  HiFilter,
  HiPlus,
  HiSortAscending,
  HiSortDescending,
  HiX,
} from "react-icons/hi";
import { FaFileImport } from "react-icons/fa";

import DropDownMenu from "../../elements/DropDownMenu";
import PanelModal from "../../PanelModal";
import util from "../../util";
import Icons from "../../../orders/icons";
import { utils, writeFileXLSX } from "xlsx";

const Title = ({
  form,
  displayTitle = "flex",
  sort,
  filter,
  actionList,
  options,
  exports,
  headerOption,
  logo,
  title,
  subTitle,
  setImportModal,
  allowExport,
  filtering,
  selectedRow,
  exported,
  loading,
  onExportWithNotes,
}) => {
  const [filterModalVisibility, setFilterModalVisibility] = useState(false);
  // const mappedFilters = [];
  // filter?.items?.map((item) => {
  //   if (item?.defaultValue) {
  //     mappedFilters.push(item?.label);
  //   }
  // });
  const selectedSort = sort?.items?.list?.find((item) => {
    return (
      sort?.selected &&
      (sort?.selected?.startsWith("-")
        ? sort?.selected?.substring(1)
        : sort?.selected) === item.value
    );
  });

  const dataToBeExported = exported
    ? exported.exportMapper(exported.exportData)
    : [];

  const exportFile = useCallback(() => {
    if (title.includes("Orders") && onExportWithNotes) {
      onExportWithNotes(exported.exportData).then((data) => {
        const enhancedData = exported.exportMapper(data);
        const ws = utils.json_to_sheet(enhancedData);
        utils.sheet_add_aoa(ws, [exported.headers], { origin: "A1" });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, `${title}.xlsx`);
      });
    } else {
      const ws = utils.json_to_sheet(dataToBeExported);
      utils.sheet_add_aoa(ws, [exported.headers], { origin: "A1" });
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `${title}.xlsx`);
    }
  }, [dataToBeExported, title, onExportWithNotes]);

  const exportCSV = useCallback(() => {
    if (title.includes("Orders") && onExportWithNotes) {
      onExportWithNotes(exported.exportData).then((data) => {
        const enhancedData = exported.exportMapper(data);
        const worksheet = utils.json_to_sheet(enhancedData);
        const csvOutput = utils.sheet_to_csv(worksheet);
        const blob = new Blob([csvOutput], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", `${title}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    } else {
      const worksheet = utils.json_to_sheet(dataToBeExported);
      const csvOutput = utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvOutput], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${title}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [dataToBeExported, title, onExportWithNotes]);

  function mapValues(obj) {
    // Helper function to map a single value
    function mapValue(value, key) {
      if (Array.isArray(value)) {
        // If it's an array
        if (value.length === 0) return ""; // Empty array
        if (typeof value[0] === "object" && value[0] !== null) {
          // If it's an array of objects
          return value;
        } else {
          // If it's an array of primitives and has more than one element
          if (value.length > 1) {
            //  if it's a date range filter return the date range in the format mmm dd, yyyy
            if (
              filter.items.find((item) => item.name === key)?.type ===
              "DateRange"
            ) {
              return value
                .map((date) => {
                  // if it's invalid date return '
                  if (isNaN(new Date(date).getTime())) return "";
                  return new Date(date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  });
                })
                .filter((date) => date !== "")
                .join(" to ");
            }
            return value.join(" to ");
          } else if (value.length === 1) {
            return [value[0], "∞"].join(" to ");
          }
        }
      } else if (typeof value === "object" && value !== null) {
        // If it's an object
        return value.label;
      } else {
        // If it's a primitive
        if (
          value &&
          filter.items.find((item) => item.name === key)?.encode === true
        )
          return decodeURIComponent(value);
        return value;
      }
    }

    // Map the keys to their corresponding values
    const mappedObject = {};
    for (const key in obj) {
      mappedObject[key] = mapValue(obj[key], key);
    }
    // exclude the keys that are inside the filter.discard array if it exiqts
    if (filter?.discard) {
      filter.discard.map((key) => {
        delete mappedObject[key];
      });
    }

    // from mappedObject remove the filters that are not  in the pageFilters
    Object.keys(mappedObject).map((key) => {
      if (!filter.pageFilters[key]) {
        delete mappedObject[key];
      }
    });

    // delete the keys that includes "status"
    // Object.keys(mappedObject).map((key) => {
    //   if (key.includes("status")) {
    //     delete mappedObject[key];
    //   }
    // });
    return mappedObject;
  }

  const mappedFilters = mapValues(filter?.filters);

  if (Object.keys(mappedFilters).some((key) => mappedFilters[key])) {
    mappedFilters["_"] = (
      <UI.Button
        item={{
          label: <HiX className="w-3 h-3" />,
          buttonType: "icon",
          className:
            "order-[0] !p-0 !text-red-700 !border-red-700 !h-[22px] !min-w-[22px]",
        }}
        onClick={() => {
          filter.callback(
            {
              ...filter.pageFilters,
              ...Object.keys(mappedFilters).reduce((acc, key) => {
                acc[key] = null;
                return acc;
              }, {}),
            },
            {
              ...filter.filters,
              ...Object.keys(mappedFilters).reduce((acc, key) => {
                acc[key] = null;

                return acc;
              }, {}),
            }
          );
        }}
      />
    );
  }

  const renderFilters = () => {
    const filterElements = [];
    Object.keys(mappedFilters)
      .reverse()
      .forEach((key) => {
        if (!mappedFilters[key]) return;
        // render mappedFilters["_"] as it is a button
        if (key === "_") {
          filterElements.unshift(mappedFilters[key]);
          return;
        }
        if (Array.isArray(mappedFilters[key])) {
          const values = mappedFilters[key].map((filterValue, index) => (
            <span key={`${key}-${index}`} className="flex items-center">
              <span className="text-blue-800 text-xs font-medium font-['Inter'] leading-[18px]">
                {filterValue.label}
              </span>
              <HiX
                className="h-3 w-3 text-blue-400 cursor-pointer hover:text-blue-500 ml-1"
                onClick={() => {
                  const updatedFilter = [
                    ...mappedFilters[key]?.map((item) => item.value),
                  ];
                  updatedFilter.splice(index, 1); // Remove the selected filter
                  const updateBoundFilter = mappedFilters[key].filter(
                    (item) => item.value !== filterValue.value
                  );
                  const updatedBoundFilters = {
                    ...filter.filters,
                    [key]: updateBoundFilter,
                  };
                  filter.callback(
                    {
                      ...filter.pageFilters,
                      [key]: updatedFilter.length > 0 ? updatedFilter : null,
                    },
                    updatedBoundFilters
                  );
                }}
              />
              {index < mappedFilters[key].length - 1 && (
                <span className="mx-1 text-blue-200">|</span> // Add separator between values
              )}
            </span>
          ));

          filterElements.push(
            <div
              key={key}
              className=" max-w-[calc(100%-28px)] min-h-[22px] px-2 py-0.5 rounded border border-blue-300 justify-center items-center gap-2 inline-flex"
            >
              <div className="text-blue-500 text-xs font-medium font-['Inter'] leading-[18px]">
                {filter.items.find((item) => item.name === key)?.label ||
                  key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
              </div>
              <div className="flex flex-wrap text-blue-800 text-xs font-medium font-['Inter'] leading-[18px]">
                {values}
              </div>
            </div>
          );
        } else {
          filterElements.push(
            <div
              key={key}
              className="min-w-fit h-[22px] px-2 py-0.5 rounded border border-blue-300 justify-center items-center gap-2 inline-flex"
            >
              <div className="text-blue-500 text-xs font-medium font-['Inter'] leading-[18px]">
                {filter.items.find((item) => item.name === key)?.label ||
                  key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
              </div>
              <div className="text-blue-800 text-xs font-medium font-['Inter'] leading-[18px]">
                {mappedFilters[key]}
              </div>
              <HiX
                className="h-3 w-3 text-blue-400 cursor-pointer hover:text-blue-500"
                onClick={() => {
                  filter.callback(
                    {
                      ...filter.pageFilters,
                      [key]: null,
                    },
                    { ...filter.filters, [key]: null }
                  );
                }}
              />
            </div>
          );
        }
      });

    return filterElements;
  };
  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null) {
          return false;
        }
      }
    }
    return true;
  };

  const getExportMenuItems = () => {
    const baseMenuItems = [
      {
        label: "XLSX",
        onClick: () => {
          if (loading !== "idle") return;
          exportFile();
        },
      },
      {
        label: "CSV",
        onClick: () => {
          if (loading !== "idle") return;
          exportCSV();
        },
      },
    ];


    return baseMenuItems;
  };

  return (
    <div className=" flex-col ">
      <div
        className={`${displayTitle} h-[46px] bg-blue-50 flex  justify-between items-center px-[16px]`}
      >
        <p className="text-[20px] leading-[30px] min-w-fit font-bold text-blue-700 flex flex-row items-center gap-[0px]">
          {logo && subTitle && (
            <util.popUp
              toggle={(selected) => (
                <div className="flex flex-row items-center ml-2">
                  <img src={logo} className="h-[48px] w-[48px] mr-[8px]" />{" "}
                </div>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
            >
              {subTitle}
            </util.popUp>
          )}

          {util.shortenString(title, 50)}
          {subTitle && !logo && (
            <>
              <span className={"opacity-50"}> / </span>
              {util.shortenString(subTitle, 50)}
            </>
          )}
        </p>
        <div className=" flex flex-row justify-center items-end gap-[6px]">
          {form && (
            <UI.Button
              item={{
                label: <HiPlus className="h-5 w-5" />,
                buttonType: "icon",
                className: "order-[0] !min-w-[28px] !h-[28px] !p-0",
              }}
              onClick={form.callback}
            />
          )}
          {options &&
            options?.length > 0 &&
            options.map((option, index) => {
              if (option.condition && !option.condition()) return null;
              return (
                <div
                  className={"relative "}
                  style={{ order: option.order }}
                  key={index}
                >
                  {option.view({
                    data: option.data,
                    callBack: option.callBack,
                  })}
                </div>
              );
            })}
          {headerOption &&
            headerOption?.length > 0 &&
            headerOption.map((option, index) => {
              return (
                <div
                  className={"relative "}
                  style={{ order: option.order }}
                  key={index}
                >
                  {option.view}
                </div>
              );
            })}

          {allowExport && (
            <DropDownMenu
              toggleButton={(selected) => (
                <UI.Button
                  item={{
                    label: <Icons.cloudArrowDown className="h-5 w-5" />,
                    buttonType: "icon",
                    className: "order-[] !min-w-[28px] !h-[28px] !p-0",
                    disabled: loading !== "idle",
                  }}
                />
              )}
              // selected={sort?.selected}
              itemMenu={{
                list: getExportMenuItems(),
              }}
            />
          )}
          {exports &&
            exports?.length > 0 &&
            exports.map((option, index) => {
              return (
                <div
                  className={"relative "}
                  style={{ order: option.order }}
                  key={index}
                >
                  {option.view({
                    data: option.data,
                    callBack: option.callBack,
                  })}
                </div>
              );
            })}
          {filter && (
            <div className={"relative"}>
              <UI.Button
                item={{
                  label: <HiFilter className="h-5 w-5" />,
                  buttonType: "icon",
                  className: "order-[1] !min-w-[28px] !h-[28px] !p-0",
                }}
                onClick={() => {
                  setFilterModalVisibility(!filterModalVisibility);
                }}
              />

              {filterModalVisibility && (
                <PanelModal
                  filtersView={filter.items}
                  skipStatus={filter.skipStatus}
                  onApply={(rs, bound) => {
                    filter.callback(rs, bound);
                    setFilterModalVisibility(false);
                  }}
                  cancel={() => setFilterModalVisibility(false)}
                  visible={filterModalVisibility}
                />
              )}
              <div
                className={`absolute flex -top-[6px]  font-bold -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[18px] min-w-[18px] items-center justify-center ${
                  filter?.count < 1 ? "hidden" : "block"
                }`}
              >
                {filter?.count}
              </div>
            </div>
          )}
          {sort && (
            <div className={"relative order-[2]"}>
              <DropDownMenu
                toggleButton={(selected) => (
                  <UI.Button
                    item={{
                      label: sort?.selected ? (
                        sort?.selected?.startsWith("-") ? (
                          <>
                            <div className="flex gap-1.5 py-1 px-2 items-center ">
                              <HiSortDescending className="h-[20px] w-[20px]  " />
                              <span className="text-[12px] leading-[18px] font-medium  ">
                                {selectedSort?.label}{" "}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex gap-1.5 py-1 px-2 items-center ">
                              <HiSortAscending className="h-[20px]  w-[20px]" />
                              <span className="text-[12px] leading-[18px] font-medium  ">
                                {selectedSort?.label}{" "}
                              </span>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <HiSortDescending className="h-5 w-5   " />
                        </>
                      ),
                      buttonType: "icon",
                      className: `${
                        selected || sort?.selected
                          ? " !text-blue-700 !border-blue-700 !h-[28px] !min-w-[28px] !p-0"
                          : "text-red-600 !p-0 !h-[28px] !w-[28px] !min-w-[28px]"
                      } ! !flex !items-center !justify-center !gap-[2px]`,
                    }}
                    onClick={sort.callback}
                  />
                )}
                selected={sort?.selected}
                itemMenu={sort.items}
              />
            </div>
          )}

          {actionList?.count > 0 && actionList?.list.length > 0 && (
            <div className={"relative"}>
              <DropDownMenu
                toggleButton={(selected) => (
                  <UI.Button
                    item={{
                      label: `Actions`,
                      buttonType: "icon",
                      className: ` !max-h-[28px] ${
                        selected ? "bg-blue-200 text-blue-600" : "text-red-600"
                      } w-[100px]`,
                    }}
                    onClick={actionList.callback}
                  >
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </UI.Button>
                )}
                item={selectedRow}
                itemMenu={actionList}
              />
              <div
                className={`absolute flex  -top-[6px]  font-bold -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[18px] min-w-[18px] items-center justify-center ${
                  actionList?.count < 1 ? "hidden" : "block"
                }`}
              >
                {actionList?.count}
              </div>
            </div>
          )}

          {
            <div
              className={`absolute -top-[6px] -right-[4px] text-center rounded-full bg-pink-500 text-xs text-white h-[14px] w-[14px] ${
                !filtering && "hidden"
              }`}
            >
              <span></span>
            </div>
          }

          {setImportModal && (
            <FaFileImport
              onClick={() => {
                setImportModal(true);
              }}
              className="title-icon p-[4px] rounded h-[34px] w-[32px]"
            />
          )}
        </div>
      </div>
      {!isEmpty(mappedFilters) && renderFilters()?.length !== 0 && (
        <div className=" flex flex-row flex-wrap  justify-end items-end py-2 px-4 gap-[4px]">
          {renderFilters()}
        </div>
      )}
    </div>
  );
};

Title.propTypes = {
  marginX: PropTypes.string,
  marginY: PropTypes.string,
  height: PropTypes.string,
  justify: PropTypes.string,
  textSize: PropTypes.string,
  filters: PropTypes.object,
  actionList: PropTypes.shape({
    count: PropTypes.number,
    list: PropTypes.array,
    callback: PropTypes.func,
  }),
  sort: PropTypes.shape({
    selected: PropTypes.string,
    items: PropTypes.shape({
      list: PropTypes.array,
    }),
    callback: PropTypes.func,
  }),
  filter: PropTypes.shape({
    items: PropTypes.array,
    filters: PropTypes.object,
    callback: PropTypes.func,
    count: PropTypes.number,
    pageFilters: PropTypes.object,
    discard: PropTypes.array,
    skipStatus: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      view: PropTypes.func,
      order: PropTypes.number,
      data: PropTypes.any,
      callBack: PropTypes.func,
      condition: PropTypes.func,
    })
  ),
  exports: PropTypes.array,
  headerOption: PropTypes.array,
  logo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  setImportModal: PropTypes.func,
  allowExport: PropTypes.bool,
  filtering: PropTypes.bool,
  selectedRow: PropTypes.any,
  exported: PropTypes.shape({
    exportMapper: PropTypes.func,
    exportData: PropTypes.array,
    headers: PropTypes.array,
  }),
  loading: PropTypes.string,
  displayTitle: PropTypes.string,
  form: PropTypes.shape({
    callback: PropTypes.func,
  }),
  onExportWithNotes: PropTypes.func,
};

Title.defaultProps = {
  marginX: "16px",
  marginY: "2",
  height: "36px",
  justify: "start",
  textSize: "24px",
  filters: {},
};

export default Title;
